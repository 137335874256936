import { KSSTooltip } from './KSSTooltip';
import { IconButton, Typography } from '@material-ui/core';
import { ReactComponent as InfoIcon } from '../images/infoIcon.svg';
import React, { useEffect, useRef, useState } from 'react';

export const InfoButton: React.FC<{ text: string | undefined }> = ({
  text,
}) => {
  const [open, setOpen] = useState(false);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement>();
  const beforeRef = useRef<HTMLSpanElement>(null);
  const afterRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    tooltipRef?.focus();
  }, [tooltipRef]);

  useEffect(() => {
    const eventListener = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        setOpen(false);
        afterRef.current?.focus();
      }
    };
    window.addEventListener('keydown', eventListener);

    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  }, []);
  return (
    <>
      <span tabIndex={0} ref={beforeRef} />
      <KSSTooltip
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        enterTouchDelay={50}
        leaveTouchDelay={20000}
        title={
          <>
            <div
              tabIndex={0}
              onFocus={() => {
                beforeRef.current?.focus();
              }}
            />
            <div
              tabIndex={0}
              ref={(element) => setTooltipRef(element ?? undefined)}
              style={{ outlineColor: 'transparent' }}
            >
              {text && (
                <>
                  <Typography
                    variant="body1"
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </>
              )}
            </div>
            <div
              tabIndex={0}
              onFocus={() => {
                afterRef.current?.focus();
              }}
            />
          </>
        }
        interactive
        placement="bottom"
      >
        <IconButton aria-label="info">
          <InfoIcon style={{ width: 20 }} />
        </IconButton>
      </KSSTooltip>
      <span tabIndex={0} ref={afterRef} />
    </>
  );
};
