import { UseQueryArgs } from 'urql';
import { ImageFormat } from './types';

const createQueryWithVariables = <T extends object>(query: string) => {
  return (arg: T): UseQueryArgs<T> => ({
    query: query,
    variables: arg,
  });
};

const createQuery = (query: string) => {
  return () => ({
    query: query,
  });
};

const image = (imageFormat?: ImageFormat) => {
  switch (imageFormat) {
    case undefined:
      return '';
    case 'svg':
      return `image {
    ... on media_Vector {
      mediaUrl(type:absolute)
      data { caption artist }
    }
   }`;
    default:
      return `image {
    ... on media_Image {
      imageUrl(scale:"${imageFormat}", type:absolute)
      data { caption artist}
    }
   }`;
  }
};

const articleSectionData = `
  blockHeading
  blockAnchor
  article(processHtml:{type:absolute}) {
    raw
    processedHtml
    macrosAsJson
  }
  images {
    ${image('width(400)')}
    text(processHtml:{type:absolute}) {
      raw
      processedHtml
      macrosAsJson
    }
  }
  factbox {
    content(processHtml:{type:absolute}) {
      raw
      processedHtml
      macrosAsJson
    }
  }
`;

const articleFragment = `... on no_bouvet_met_kss_ArtikkelKSS {
  data {
    topimage {
      ${image('block(750,250)')}
      text(processHtml:{type:absolute}) {
        raw
        processedHtml
        macrosAsJson
      }
    }
    preface
    lastUpdated
    article {
      ${articleSectionData}
      externalArticleBlock {
        ... on no_bouvet_met_kss_ArticleBlock {
          data {
            ${articleSectionData}
          }
        }
      }
    }
  }
}`;

const linkFragment = (imageFormat?: ImageFormat) =>
  `... on no_bouvet_met_kss_LenkeKSS {
    displayName
    type
    data {
      text {
        raw
        processedHtml
        macrosAsJson
      }
      urls {
        internal {
          anchorTag
          contentKey {
            pageUrl
          }
        }
        external {
          url
        }
      }
      ${image(imageFormat)}
    }
  }`;

export const getPageData = createQueryWithVariables<{ path: string }>(
  `query($path: ID!) {  
    guillotine {
      get(key: $path) {
        createdTime
        modifiedTime
        type
        displayName
        language
        ${articleFragment}
      }
    }
  }`,
);

export const getHelptext = createQueryWithVariables<{ path: string }>(
  `query($path: ID!) {
    guillotine {
      get(key: $path) {
        ... on no_bouvet_met_kss_HjelpetekstKSS {
          type
          displayName
          data {
            text(processHtml: { type:absolute }) {
              raw
              processedHtml
              macrosAsJson
            }
          }
        }
      }
    }
  }`,
);

export const getLinks = (imageFormat?: ImageFormat) =>
  createQueryWithVariables<{ path: string }>(
    `query($path: ID!) {  
      guillotine {
        get(key: $path) {
          displayName
          children(first: 100) {
            ${linkFragment(imageFormat)}
          }
        }
      }
    }`,
  );

export const getLink = (imageFormat?: ImageFormat) =>
  createQueryWithVariables<{ path: string }>(
    `query($path: ID!) {  
      guillotine {
        get(key: $path) {
          displayName
          ${linkFragment(imageFormat)}
        }
      }
    }`,
  );

export const getSiteStructure = createQuery(
  `query {
    guillotine {
      getSite {
        children(first: 100) {
          _id
          type
          displayName
          pageUrl
          dataAsJson
          ${linkFragment()}
          children(first: 100) {
            _id
            type
            displayName
            pageUrl
            dataAsJson
            x {
              no_bouvet_met_kss {
                menu_item	{
                  menuItem
                  menuName
                }
              }
            }
            ${linkFragment()}
          }
        }
      }
    }
  }`,
);
