import React from 'react';

interface Props {
  errorMessage: string;
}

export const ErrorPage: React.FC<Props> = (props: Props) => {
  React.useEffect(() => {
    document.title = 'Error page - Norsk klimaservicesenter';
    document.documentElement.lang = 'nb';
  }, []);

  return (
    <>
      <h1>Error</h1>
      <p>{props.errorMessage}</p>
    </>
  );
};
