import { createTheme } from '@material-ui/core';
import { configureTypography } from './typography';

const palette = {
  primary: {
    light: '#d6eed3',
    main: '#00501b',
    dark: '#112e0c',
  },
  background: {
    default: '#FFF',
  },
  warning: {
    main: '#FFEA9C',
  },
  error: {
    main: '#f12929',
  },
};

export default function KSScreateTheme() {
  const theme = createTheme({
    palette,
  });

  theme.typography = {
    ...theme.typography,
    ...configureTypography(theme),
  };

  return theme;
}
