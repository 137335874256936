import React, { useState } from 'react';
import {
  Box,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  Link,
  ListItem,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

import { Menu } from './types';
import { isMenuFolder } from './navigationUtils';
import { getLinkProps } from 'services/helpers';
import { SearchBar } from 'components/SearchBar';
import { LanguageSelector } from 'components/LanguageSelector';
import { useNavigationContext } from 'services/navigationContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuSection: {
      '& + &': {
        marginTop: theme.spacing(3),
      },
    },
    menuDrawer: {
      width: '100vw',
      maxWidth: 350,
      padding: theme.spacing(2),
    },
    levelOneLink: {
      borderBottom: `1px solid ${theme.palette.text.secondary}`,
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 500,
      color: 'inherit',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        textDecoration: 'none',
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    activeMenuLink: {
      backgroundColor: theme.palette.primary.light,
      '&:hover, &:focus': {
        backgroundColor: alpha(theme.palette.primary.light, 0.8),
      },
    },
    levelTwoLink: {
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    nestedListItem: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export const MobileMenus: React.FC = () => {
  const styles = useStyles();
  const { menus, activeMenuFolder } = useNavigationContext();

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleOpen} anchor="right">
        <Box className={styles.menuDrawer}>
          <Grid
            container
            justifyContent="space-between"
            className={styles.menuSection}
          >
            <LanguageSelector />
            <IconButton
              color="primary"
              aria-label="close drawer"
              edge="start"
              onClick={toggleOpen}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Box className={styles.menuSection}>
            <SearchBar greenBorder={true} />
          </Box>

          <Box className={styles.menuSection}>
            {menus.map((menu) => (
              <MobileMenu
                key={menu.name}
                menu={menu}
                isActiveMenuFolder={
                  isMenuFolder(menu) && activeMenuFolder === menu
                }
                closeDrawer={() => setOpen(false)}
              />
            ))}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

interface MobileMenuProps {
  menu: Menu;
  isActiveMenuFolder: boolean;
  closeDrawer: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  menu,
  isActiveMenuFolder,
  closeDrawer,
}) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const expandCollapseIcon = open ? <ExpandLess /> : <ExpandMore />;

  return isMenuFolder(menu) ? (
    <>
      <ListItem
        button
        onClick={() => setOpen(!open)}
        className={`${styles.levelOneLink} ${
          isActiveMenuFolder ? styles.activeMenuLink : ''
        }`}
      >
        {menu.name}
        {isMenuFolder(menu) && expandCollapseIcon}
      </ListItem>
      <Collapse in={open} timeout="auto">
        {menu.links.map((link) => (
          <Link
            key={link.name}
            {...getLinkProps(link.path)}
            onClick={closeDrawer}
            className={styles.levelTwoLink}
            lang={
              link.name === 'About NCCS and links to English pages' ||
              link.name === 'Norwegian Centre for Climate Services'
                ? 'en'
                : 'nb'
            }
          >
            <ListItem button className={styles.nestedListItem}>
              {link.name}
            </ListItem>
          </Link>
        ))}
      </Collapse>
    </>
  ) : (
    <Link
      {...getLinkProps(menu.path)}
      onClick={closeDrawer}
      className={styles.levelOneLink}
    >
      <ListItem button>{menu.name}</ListItem>
    </Link>
  );
};
