import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ClimateIndex } from '@Types/ClimateIndex';
import ScenarioMap from './ScenarioMap';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Typography } from '@material-ui/core';
import { ScenarioMapLegend } from './ScenarioMapLegend';

const useStyles = makeStyles((theme: Theme) => {

    return createStyles({
        mapSection: {
            border: '1px solid lightgray',
        },
        buttonGroup: {
            width: '100%',
            color: 'black',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        yearButton: {
            width: '50%',
            color: 'black',
        },
        link: {
            color: 'blue',
            whiteSpace: 'normal',
            '&:hover': {
                color: theme.palette.primary.main,
            },
            textDecoration: 'underline'
        },
        mapHeading: {
            textAlign: 'center',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0),
        },
        descContainer: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        }
    });
});

interface Props {
    currentIndex: ClimateIndex;
    mapHeading?: string;
    period: string;
    scenario: string;
    area: string;
    mapYear: string;
    descriptionHTML: string;
    handleYearChanged: ((event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void) | undefined;
    handleAreaSelected: (areaId: string) => void;

}

export const ScenarioMapSection: React.FC<Props> = ({
    currentIndex,
    mapHeading,
    period,
    scenario,
    area,
    mapYear,
    descriptionHTML,
    handleYearChanged,
    handleAreaSelected,
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className={classes.mapSection}>
                <Typography component="div" variant="body1" className={classes.mapHeading}>
                    {mapHeading}
                </Typography>
                <ToggleButtonGroup
                    className={classes.buttonGroup}
                    exclusive
                    value={mapYear}
                    onChange={handleYearChanged}
                >
                    <ToggleButton className={classes.yearButton} value="2046">
                        <Typography variant="body1">2031 - 2060</Typography>
                    </ToggleButton>
                    <ToggleButton className={classes.yearButton} value="2085">
                        <Typography variant="body1">2071 - 2100</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                {currentIndex && (
                    <ScenarioMap
                        index={currentIndex}
                        period={period}
                        scenario={scenario}
                        area={area}
                        year={mapYear}
                        onAreaSelect={handleAreaSelected}
                    />
                )}
                {(currentIndex && currentIndex.legendFile) && (
                    <ScenarioMapLegend legendFile={currentIndex.legendFile} />
                )}
            </div>
            <Typography component="div" variant="body1" className={classes.descContainer}>
                <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
            </Typography>
            {currentIndex.obsFilePattern && (
                <p>
                    <Link href="#" variant="body1" className={classes.link} onClick={handleClickOpen}>
                        Kart for referanseperioden 1971-2000
                    </Link>
                </p>
            )}
            <Link href="https://nedlasting.nve.no/klimadata/kss" target="_blank" variant="body1" className={classes.link}>
                Klimamodelldata med oppløsning 1 x 1 km og døgn er tilgjengelig i vår nedlastingsløsning
            </Link>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Kart for referanseperioden 1971-2000
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <img
                            src={'/map-layers/' + currentIndex.obsFilePattern.replace('%p', period.toLowerCase())}
                            alt={'Kart for referanseperioden 1971-2000'}
                            style={{ width: '100%' }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Lukk
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
