import React, { useContext } from 'react';
import { Article } from '../cms/types';
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeSlug } from 'services/helpers';
import { APIContext } from 'utils/contextUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerHr: {
      border: '0px',
      height: '0.5px',
    },
    articlePreface: {
      marginBottom: theme.spacing(4),
    },
  }),
);

export const IntroText: React.FC<Article> = ({ data, ...props }: Article) => {
  const classes = useStyles();
  const articleSections = data.article ? data.article : [];
  const history = useHistory();

  const configContext = useContext(APIContext);
  const REACT_APP_GRAPHQL_LINK_ROOT = configContext.get(
    'REACT_APP_GRAPHQL_LINK_ROOT',
  );

  const handlePossibleLinkClick = (event: any) => {
    if (event.target.tagName !== 'A') return;
    const element: HTMLAnchorElement = event.target;
    if (element.href.includes('download')) return;

    const linkRoot = REACT_APP_GRAPHQL_LINK_ROOT ?? '';

    if (linkRoot !== '' && element.href.includes(linkRoot)) {
      event.preventDefault();
      //we needed to add kss becuase linkroot messed with met urls.
      const pageLink = '/kss' + element.href.split(linkRoot)[1];
      history.push(pageLink);
    }
  };

  return (
    <>
      <Typography variant="h1">{props.displayName}</Typography>
      <hr className={classes.headerHr} />
      <Typography variant="body1" className={classes.articlePreface}>
        {data.preface}
      </Typography>
      {articleSections.map((section, articleIndex) => (
        <Box
          key={`article-${articleIndex}`}
          onClick={(event) => handlePossibleLinkClick(event)}
        >
          {section.blockHeading && (
            <Typography variant="h2" id={makeSlug(section.blockHeading)}>
              {section.blockHeading.trim()}
            </Typography>
          )}
          <Typography
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: section.article?.processedHtml }}
          />
        </Box>
      ))}
    </>
  );
};
