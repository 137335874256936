import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from 'urql';
import { getSiteStructure } from 'cms/queries';
import {
  CmsResult,
  Link,
  RawNavigationData,
  RawData,
  isFolder,
  isLink,
  isArticle,
} from 'cms/types';
import {
  MenuLink,
  Menu,
  MenuFolder,
  isMenuFolder,
} from 'components/navigation';
import { getUrlFromLinkContent } from 'services/helpers';
import { useLocation } from 'react-router-dom';

interface NavigationContext {
  menus: Menu[];
  activeMenuFolder: MenuFolder | null;
}

const initialState: NavigationContext = {
  menus: [],
  activeMenuFolder: null,
};

const convertToMenuLink = (rawData: RawData): MenuLink => {
  let path = rawData.pageUrl;

  if (isLink(rawData)) {
    const urlData = (rawData as Link).data?.urls;
    path = getUrlFromLinkContent(urlData);
  }

  const menuName = rawData.x?.no_bouvet_met_kss?.menu_item?.menuName;

  const name = menuName ?? rawData.displayName;

  return {
    id: rawData._id,
    name,
    path,
  };
};

const includeInMenu = (contentElement: RawData) => {
  const rawShouldShowMenu =
    contentElement.x?.no_bouvet_met_kss?.menu_item?.menuItem;
  const shouldShowMenu = rawShouldShowMenu?.toLowerCase() === 'true';
  return (
    (isArticle(contentElement) || isLink(contentElement)) && shouldShowMenu
  );
};

const Context = createContext<NavigationContext>(initialState);
export const useNavigationContext = () => useContext(Context);

const siteStructure = getSiteStructure();
export const NavigationProvider: React.FC<{}> = ({ children }) => {
  const location = useLocation();
  const [result] = useQuery<CmsResult<RawNavigationData>>(siteStructure);

  const [menus, setMenus] = useState<Menu[]>(initialState.menus);
  const [activeMenuFolder, setActiveMenuFolder] = useState<MenuFolder | null>(
    initialState.activeMenuFolder,
  );

  useEffect(() => {
    setActiveMenuFolder(null);

    const activeMenuFolder = menus
      .filter(isMenuFolder)
      .find((menu) =>
        menu.links.find(
          (link) =>
            link.path.split('?')[0] === location.pathname ||
            link.path.split('#')[0] === location.pathname,
        ),
      );

    if (activeMenuFolder) {
      setActiveMenuFolder(activeMenuFolder);
    }
  }, [location.pathname, menus]);

  useEffect(() => {
    const rawMenus = result.data?.guillotine.getSite?.children;

    if (result.fetching || result.error || !rawMenus) return;

    const menusToDisplay = rawMenus.filter((rawMenu) => {
      const menuHasLinks =
        isFolder(rawMenu) && rawMenu.children.some(includeInMenu);
      return menuHasLinks || includeInMenu(rawMenu);
    });

    const menus = menusToDisplay.map((rawMenu): Menu => {
      if (isFolder(rawMenu)) {
        const menuLinks = rawMenu.children;
        return {
          name: rawMenu.displayName,
          links: menuLinks.filter(includeInMenu).map(convertToMenuLink),
        };
      }
      return convertToMenuLink(rawMenu);
    });

    setMenus(menus);
  }, [result]);

  return (
    <Context.Provider value={{ menus, activeMenuFolder }}>
      {children}
    </Context.Provider>
  );
};

NavigationProvider.displayName = 'NavigationProvider';
