import { Theme } from '@material-ui/core';

import 'fontsource-roboto/latin-400.css';
import 'fontsource-roboto/latin-500.css';
import 'fontsource-roboto/latin-700.css';

const responsiveFontSize = (theme: Theme, sm: number, md: number, lg: number, xlg?: number) => ({
  fontSize: theme.typography.pxToRem(sm),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(md),
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(lg),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(xlg || lg),
  }
});

const fontFamily = { fontFamily: ['Roboto', 'Arial'].join(', ') };

export const configureTypography = (theme: Theme): object => ({
  // Default
  ...fontFamily,
  fontSize: 14,

  // Body styles
  body1: {
    ...fontFamily,
    fontWeight: 400,
    lineHeight: 1.5,
    ...responsiveFontSize(theme, 16, 16, 18),
  },

  // Header styles
  h1: {
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    ...responsiveFontSize(theme, 28, 36, 40),
  },
  h2: {
    fontWeight: 700,
    ...responsiveFontSize(theme, 22, 30, 32),
  },
  h3: {
    fontWeight: 700,
    ...responsiveFontSize(theme, 20, 24, 26),
  },
  h4: {
    fontWeight: 700,
    ...responsiveFontSize(theme, 18, 20, 24),
  },
  h5: {
    fontWeight: 700,
    ...responsiveFontSize(theme, 17, 18, 22),
  },
  h6: {
    fontWeight: 700,
    ...responsiveFontSize(theme, 16, 16, 18),
  },
});
