import React, { useState, useEffect } from 'react';
import { useQuery } from 'urql';
import {
  Box,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { CmsResult, Link, RawData, isLink } from 'cms/types';
import { getLink, getLinks } from 'cms/queries';
import {
  CardSection,
  Newsbubble,
  NewsbubbleProps,
  WarmingStripes,
  WeatherBox,
} from 'components/frontpage';
import { Dropdown } from 'components/Dropdown';
import { SearchBar } from 'components/SearchBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topImageContainer: {
      width: '100vw',
      height: '400px',
      overflow: 'hidden',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        height: '300px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '200px',
      },
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center top',
    },
    imageText: {
      position: 'absolute',
      top: '33%',
      width: '100%',
      color: 'white',
    },
    overlay: {
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0.35,
    },
    imageTextLarge: {
      fontWeight: 300,
      paddingBottom: '10px',
      fontSize: '42px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
        width: '70%',
        margin: 'auto',
        paddingTop: theme.spacing(2),
      },
    },
    imageTextItalic: {
      fontWeight: 300,
      fontStyle: 'italic',
      fontSize: '24px',
      paddingBottom: '10px',
    },
    imageTextSmall: {
      fontSize: '14px',
    },
    linksColumn: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
      },
    },
    weatherBoxes: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(10),
      },
    },
    weatherboxSectionTitle: {
      marginBottom: theme.spacing(2),
    },
    map: {
      padding: theme.spacing(4),
    },
  }),
);

export const FrontPage: React.FC = () => {
  const classes = useStyles();
  const [newsbubble, setNewsbubble] = useState<NewsbubbleProps>({
    title: '',
    content: '',
  });

  const [mapLinksResult] = useQuery<CmsResult<RawData>>(
    getLinks()({
      path: '/kss/forside/kart',
    }),
  );
  const mapLinks = mapLinksResult.data?.guillotine.get?.children
    .filter((rawData) => isLink(rawData))
    .map((element) => element as Link)
    .map((link) => ({
      title: link.displayName,
      urlData: link.data?.urls,
    }));

  const [reportLinkResult] = useQuery<CmsResult<Link>>(
    getLink()({
      path: '/kss/forside/vis-flere',
    }),
  );
  const reportLink = reportLinkResult.data?.guillotine.get;

  const [newsbubbleResult] = useQuery<CmsResult<Link>>(
    getLink()({ path: '/kss/forside/nyhet' }),
  );

  const [weatherBoxResult] = useQuery<CmsResult<RawData>>(
    getLinks('svg')({
      path: '/kss/forside/vaertema',
    }),
  );
  const weatherBoxes = weatherBoxResult.data?.guillotine.get?.children
    .filter((item) => isLink(item))
    .map((link) => link as Link);

  useEffect(() => {
    document.title = 'Forside - Norsk klimaservicesenter';
    document.documentElement.lang = 'nb';
  }, []);

  useEffect(() => {
    const resultData = newsbubbleResult.data?.guillotine.get;
    if (resultData) {
      setNewsbubble({
        title: resultData.displayName,
        content: resultData.data?.text?.processedHtml ?? '',
        link: resultData.data?.urls,
      });
    }
  }, [newsbubbleResult.data]);

  return (
    <>
      <Box className={classes.topImageContainer}>
        <img
          className={classes.image}
          src="/forsidebanner_compr.jpg"
          alt="Norsk fjord med fjell"
        />
        <div className={classes.overlay} />
        <Box className={classes.imageText}>
          <Hidden mdUp>
            <SearchBar />
          </Hidden>
          <Typography className={classes.imageTextLarge}>
            <FormattedMessage id="startpage_image_text_large" />
          </Typography>
          <Hidden smDown>
            <Typography className={classes.imageTextItalic}>
              <FormattedMessage id="startpage_image_text_italic" />
            </Typography>
            <Typography className={classes.imageTextSmall}>
              <FormattedMessage id="startpage_image_text_small" />
            </Typography>
          </Hidden>
        </Box>
      </Box>

      <Box paddingY={6}>
        <Box>
          <Grid container>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={5}>
              {mapLinks && (
                <Dropdown labelId="select_climate_profile" items={mapLinks} />
              )}
              <img
                className={classes.map}
                src="/kart.svg"
                width="100%"
                alt="Norgeskart"
              />
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid item container xs={12} md={4} direction="column">
              <Grid item>
                <Box className={classes.linksColumn}>
                  {newsbubble.title && newsbubble.content && (
                    <Newsbubble {...newsbubble} />
                  )}
                </Box>
              </Grid>
              {weatherBoxes && weatherBoxes.length > 0 && (
                <Box className={classes.weatherBoxes}>
                  <Typography
                    variant="h3"
                    className={classes.weatherboxSectionTitle}
                  >
                    <FormattedMessage id="startpage_weatherbox_title" />
                  </Typography>
                  <Grid item container justifyContent="space-between">
                    {weatherBoxes.map((wb, wbIndex) => (
                      <WeatherBox
                        key={`weatherBox-${wbIndex}`}
                        {...(wb as Link)}
                      />
                    ))}
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box marginTop={6}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={10}>
              <CardSection path="/kss/forside/temakort" />
              <CardSection path="/kss/forside/rapportkort" link={reportLink} />
              <WarmingStripes path="/kss/forside/infografikk" />
            </Grid>
            <Grid item xs={12} md={1} />
          </Grid>
        </Box>
      </Box>
    </>
  );
};
