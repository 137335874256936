import React, { Dispatch, SetStateAction, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  KSSLocale,
  useLocalizationContext,
} from 'services/localizationContext';
import IVFSearchField from 'components/IVF/IVFSearchField';
import { Data } from '../@Types/StationData';
import { getIVFStations, getIVFData } from '../services/fetchData';
import {
  Button,
  Box,
  Chip,
  createStyles,
  Grid,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { groupBy, sortBy } from 'lodash';
import { ReactComponent as Pin } from '../images/pin.svg';
import { KSSTooltip } from '../components/KSSTooltip';
import MapDialog from '../components/IVF/MapDialog';
import ClearIcon from '@material-ui/icons/Clear';
import { LocationData } from '../@Types/LocationData';
import { IvfData } from '../@Types/IVFData';
import { IVFGraphData, IVFGraphEntity } from '../@Types/IVFGraphData';
import IVFObservationPanel from '../components/IVF/IVFObservationPanel';
import { useQuery } from 'urql';
import { CmsResult, Article } from '../cms/types';
import { getPageData } from '../cms/queries';
import { compareCounty } from '../utils/comparators';
import { IVFIntroText } from '../components/IVF/IVFIntroText';
import {
  capitalizeAllWords,
  capitalizeStationName,
} from '../utils/stringUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { APIContext } from 'utils/contextUtils';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonIcons: {
      minWidth: theme.spacing(7),
      marginLeft: '0.5em',
    },
    searchGrid: {
      display: 'flex',
    },
    headerSpace: {
      paddingBottom: theme.spacing(3),
    },
    chip: {
      margin: theme.spacing(1),
    },
    pointWarningText: {
      color: theme.palette.error.main,
      paddingLeft: theme.spacing(4),
    },
    textBold: {
      fontWeight: 'bold',
    },
  }),
);

interface RouterProps {
  match: any;
}

export interface IVFDataMap {
  [stationId: string]: IVFGraphEntity | undefined;
}

type Props = RouterProps;

export const IVFPage: React.FC<RouterProps> = ({ match }: Props) => {
  const intl = useIntl();
  const [IVFStations, setIVFStations] = React.useState<Data[]>();
  const [displayMap, setDisplayMap] = React.useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = React.useState<
    LocationData[]
  >([]);
  const [ivfData, setIvfData] = React.useState<IVFGraphEntity | null>(null);
  const [ivfDataByStationId, setIvfDataByStationId] =
    React.useState<IVFDataMap>({});
  const [unit, setUnit] = React.useState('mm');
  const locationSelectLimit = 1;
  const [initialUpdate, setInitialUpdate] = React.useState(true);
  const classes = useStyles();
  const { locale, updateLocale } = useLocalizationContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uriLocale = params.get('locale');
  const locationId = params.get('locationId') ?? '';
  const history = useHistory();
  const [introTextResult] = useQuery<CmsResult<Article>>(
    getPageData({
      path:
        '/kss/hjelpetekster/seklima/ivf/ivf-intro' +
        (locale === 'en' ? '_' + locale : ''),
    }),
  );
  const introTextResultRawData = introTextResult.data?.guillotine.get;

  const configContext = useContext(APIContext);
  const REACT_APP_RIM_API_V2_URL = configContext.get(
    'REACT_APP_RIM_API_V2_URL',
  );

  React.useEffect(() => {
    document.title =
      'Nedbørintensitet (IVF-verdier) - Norsk klimaservicesenter';
    document.documentElement.lang = intl.locale === 'nb' ? 'nb' : 'en';
  }, [intl]);

  React.useEffect(() => {
    if ((uriLocale === 'en' || uriLocale === 'nb') && locale !== uriLocale) {
      updateLocale(uriLocale as KSSLocale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!REACT_APP_RIM_API_V2_URL) return;
    getIVFStations(REACT_APP_RIM_API_V2_URL).then((data) => {
      if (data && data.data) {
        let locationFound = false;
        const tmpStations: Data[] = data.data.sort(compareCounty);
        setIVFStations(tmpStations);
        for (let station of tmpStations) {
          if (station.id === locationId) {
            const ivfLocation: LocationData = {
              name: capitalizeAllWords(station.name),
              id: station.id,
              isStation: true,
              county: station.county,
              municipality: station.municipality,
              masl: station.masl,
            };
            setSelectedLocations([ivfLocation]);
            locationFound = true;
          }
        }
        if (!locationFound && locationId) {
          let latLong = locationId.split(' ');
          if (latLong.length === 2) {
            const long = parseFloat(latLong[0]).toFixed(2);
            const lat = parseFloat(latLong[1]).toFixed(2);
            const ivfLocation: LocationData = {
              name: `lat: ${lat} long: ${long}`,
              id: locationId,
              isStation: false,
            };
            setSelectedLocations([ivfLocation]);
          }
        }
        setInitialUpdate(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (locale && uriLocale !== locale) {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('locale', locale);
      history.push(
        window.location.pathname + '?' + currentUrlParams.toString(),
      );
    }
  }, [locale, uriLocale, history]);

  React.useEffect(() => {
    setIvfData(null);

    if (
      selectedLocations.length > 0 &&
      selectedLocations[0].isStation &&
      REACT_APP_RIM_API_V2_URL
    ) {
      getIVFData(selectedLocations[0].id, unit, REACT_APP_RIM_API_V2_URL).then(
        (data) => {
          if (data) {
            const parsed = parseIVFData(data);
            setIvfData(parsed);
          }
        },
      );
    }
  }, [selectedLocations, unit, REACT_APP_RIM_API_V2_URL]);

  React.useEffect(() => {
    if (REACT_APP_RIM_API_V2_URL && IVFStations) {
      const fetchPromises = IVFStations.map((station) =>
        getIVFData(station.id, unit, REACT_APP_RIM_API_V2_URL),
      );

      Promise.all(fetchPromises)
        .then((allData) => {
          const ivfDataByStationId: IVFDataMap = allData.reduce(
            (acc: IVFDataMap, data, index) => {
              if (data !== undefined) {
                const stationId = IVFStations[index].id;
                acc[stationId] = parseIVFData(data);
              }
              return acc;
            },
            {},
          );

          setIvfDataByStationId(ivfDataByStationId);
        })
        .catch((error) => {
          console.error('Error fetching IVF data for all stations:', error);
        });
    }
  }, [IVFStations, unit, REACT_APP_RIM_API_V2_URL]);

  React.useLayoutEffect(() => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    if (!initialUpdate) {
      if (selectedLocations.length) {
        currentUrlParams.set('locationId', selectedLocations[0].id);
        history.push(
          window.location.pathname + '?' + currentUrlParams.toString(),
        );
      } else {
        currentUrlParams.delete('locationId');
        history.push(
          window.location.pathname + '?' + currentUrlParams.toString(),
        );
      }
    }
  }, [history, selectedLocations, initialUpdate]);

  const isNonStationSelected = () => {
    if (selectedLocations.length > 0) {
      return !selectedLocations[0].isStation;
    }
    return false;
  };

  return (
    <Box paddingY={6}>
      <div className={classes.headerSpace}>
        <Typography variant={'h3'}>Seklima</Typography>
        {introTextResultRawData && (
          <Grid item xs={12}>
            <IVFIntroText {...introTextResultRawData} />
          </Grid>
        )}
      </div>
      <Grid container>
        <Grid item xs={12}>
          <InputLabel>
            <FormattedMessage id={'ivfSearchHeader'} />
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={6} className={classes.searchGrid}>
          <IVFSearchField
            locationSelectLimit={locationSelectLimit}
            filteredStations={IVFStations}
            setSelectedLocations={setSelectedLocations}
            selectedLocations={selectedLocations}
          />
          <KSSTooltip
            title={<FormattedMessage id={'map_tooltip'} />}
            placement="bottom"
          >
            <Button
              variant="outlined"
              className={classes.buttonIcons}
              aria-label="Location"
              onClick={() => setDisplayMap(!displayMap)}
            >
              <Pin />
            </Button>
          </KSSTooltip>
        </Grid>
        <Grid item xs={12}>
          {selectedLocations && (
            <LocationChips
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {IVFStations && displayMap && (
            <MapDialog
              locationSelectLimit={locationSelectLimit}
              filteredStations={IVFStations}
              open={displayMap}
              setDisplayMap={setDisplayMap}
              setSelectedLocations={setSelectedLocations}
              selectedLocations={selectedLocations}
              ivfDataByStationId={ivfDataByStationId}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {isNonStationSelected() && (
            <Typography className={classes.pointWarningText}>
              <FormattedMessage id={'non_station_selected'} />
              <Typography className={classes.textBold}>
                <FormattedMessage id={'select_station'} />
              </Typography>
            </Typography>
          )}
          {selectedLocations.length > 0 && selectedLocations[0].isStation && (
            <>
              <IVFObservationPanel
                ivfData={ivfData}
                selectedLocations={selectedLocations}
                unit={unit}
                setUnit={setUnit}
              />
              <Typography>
                <FormattedMessage
                  id={'met_data_licence'}
                  values={{ date: moment.utc().format('DD.MM.YYYY') }}
                />
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

interface ChipProps {
  selectedLocations: LocationData[];
  setSelectedLocations: Dispatch<SetStateAction<LocationData[]>>;
}

const LocationChips: React.FC<ChipProps> = ({
  selectedLocations,
  setSelectedLocations,
}) => {
  const classes = useStyles();
  function handleOnDelete(id: string) {
    const locations = selectedLocations.filter((el) => el.id !== id);
    setSelectedLocations(locations);
  }

  return (
    <>
      {Object.values(selectedLocations).map((item) => {
        const name = item.name;
        return (
          <Chip
            key={item.id}
            label={capitalizeStationName(name)}
            color={'primary'}
            className={classes.chip}
            deleteIcon={<ClearIcon fontSize={'small'} />}
            onDelete={() => handleOnDelete(item.id)}
          />
        );
      })}
    </>
  );
};

export const sortIvfData = (ivfData: IvfData): IvfData => {
  const sortedIvfDataValues = sortBy(ivfData.values, ['duration', 'frequency']);
  return {
    ...ivfData,
    values: sortedIvfDataValues,
  };
};

export const parseIVFData = (ivfData: IvfData): IVFGraphEntity => {
  const sortedIvfData = sortIvfData(ivfData);
  const {
    firstYearOfPeriod,
    lastYearOfPeriod,
    sourceid,
    numberOfSeasons,
    values,
    unit,
    updatedAt,
    qualityClass,
  } = sortedIvfData;

  const ivfValuesByFrequency = groupBy(values, (data) => data.frequency);
  const ivfValuesByFrequency2 = Object.entries(ivfValuesByFrequency);
  const ivfGraphData: IVFGraphData[] = ivfValuesByFrequency2.reduce<
    IVFGraphData[]
  >((ivfGraphData, [frequency, ivfValues]) => {
    const durationAndIntensityLine = ivfValues.map((value) => {
      return [value.duration, value.intensity];
    });
    const line: IVFGraphData = {
      type: 'line',
      data: durationAndIntensityLine,
      frequency: Number(frequency),
      unit,
    };

    const durationAndIntensityArea = ivfValues.map((value) => {
      return [value.duration, value.lowerinterval, value.upperinterval];
    });
    const area: IVFGraphData = {
      type: 'arearange',
      data: durationAndIntensityArea,
      frequency: Number(frequency),
      unit,
    };

    return [...ivfGraphData, line, area];
  }, []);

  return {
    IVFGraphData: ivfGraphData,
    firstYearOfPeriod,
    lastYearOfPeriod,
    locationId: sourceid,
    numberOfSeasons,
    updatedAt,
    qualityClass,
  };
};
