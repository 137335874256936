import React from 'react';
import {
  createStyles,
  Link as MuiLink,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'cms/types';
import { getLinkProps, getUrlFromLinkContent } from 'services/helpers';

interface Props {
  title: string;
  link?: Link;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      flexDirection: 'column',
      '& + $section': {
        marginTop: theme.spacing(13),
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(5),
        },
      },
    },
    sectionLink: {
      alignSelf: 'flex-end',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'underline',
      color: 'black',
    },
    linkIcon: {
      marginLeft: theme.spacing(1),
      color: 'black',
    },
  }),
);

export const Section: React.FC<Props> = ({ title, link, ...props }) => {
  const styles = useStyles();

  return (
    <div className={styles.section}>
      <Typography variant="h2">{title}</Typography>
      {props.children}
      {link && (
        <MuiLink
          className={styles.sectionLink}
          variant="body1"
          {...getLinkProps(getUrlFromLinkContent(link.data?.urls))}
        >
          {link.displayName}
          <ArrowForwardIcon className={styles.linkIcon} />
        </MuiLink>
      )}
    </div>
  );
};
