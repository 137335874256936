import React, { Dispatch, SetStateAction, useState } from 'react';
import IVFMap from './IVFMap';
import { Data } from '../../@Types/StationData';
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import IVFSearchField from './IVFSearchField';
import { FormattedMessage, useIntl } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IVFCoordinateFields from './IVFCoordinateFields';
import IVFUtmFields from './IVFUtmFields';
import { LocationData } from '../../@Types/LocationData';
import { IVFDataMap } from 'pages/IVFPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '2em 2em 1em 2em',
    },
    closeButton: {
      right: '1em',
      top: '0.85em',
      position: 'absolute',
      color: theme.palette.grey[500],
    },
    selectForm: {
      paddingBottom: theme.spacing(3),
    },
    dialog: {
      width: '90%',
    },
    pointWarningText: {
      color: theme.palette.error.main,
      paddingTop: theme.spacing(4),
    },
    textBold: {
      fontWeight: 'bold',
    },
  }),
);

interface MapDialogProps {
  filteredStations: Data[];
  open: boolean;
  setDisplayMap: Dispatch<SetStateAction<boolean>>;
  setSelectedLocations: Dispatch<SetStateAction<LocationData[]>>;
  selectedLocations: LocationData[];
  locationSelectLimit: number;
  ivfDataByStationId: IVFDataMap;
}

const MapDialog: React.FC<MapDialogProps> = ({
  filteredStations,
  open,
  setDisplayMap,
  setSelectedLocations,
  selectedLocations,
  locationSelectLimit,
  ivfDataByStationId,
}) => {
  const [searchType, setSearchType] = React.useState<string>('station');
  const [latLong, setLatLong] = useState(initLatLong());
  const classes = useStyles();
  const intl = useIntl();

  function initLatLong() {
    if (selectedLocations.length && !selectedLocations[0].isStation) {
      const latLong = selectedLocations[0].id.split(' ');
      return { lat: Number(latLong[0]), long: Number(latLong[1]) };
    }
    return { lat: 0, long: 0 };
  }

  const handleClose = () => {
    setDisplayMap(false);
  };
  const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchType(event.target.value as string);
  };

  const isNonStationSelected = () => {
    if (selectedLocations.length > 0) {
      return !selectedLocations[0].isStation;
    }
    return false;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle onClose={handleClose} />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <InputLabel>
              <FormattedMessage id={'search'} />
            </InputLabel>
            <FormControl
              variant="outlined"
              className={classes.selectForm}
              fullWidth
            >
              <Select
                value={searchType}
                onChange={handleSelect}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={'station'}>
                  {intl.formatMessage({ id: 'station_or_location' })}
                </MenuItem>
                <MenuItem value={'location'}>
                  {intl.formatMessage({ id: 'lat_long' })}
                </MenuItem>
                <MenuItem value={'utm'}>
                  {intl.formatMessage({ id: 'utm_coordinate' })}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            {searchType === 'station' && (
              <IVFSearchField
                locationSelectLimit={locationSelectLimit}
                filteredStations={filteredStations}
                setLatLong={setLatLong}
                setSelectedLocations={setSelectedLocations}
                selectedLocations={selectedLocations}
              />
            )}
            {searchType === 'location' && (
              <IVFCoordinateFields
                locationSelectLimit={locationSelectLimit}
                setSelectedLocations={setSelectedLocations}
                selectedLocations={selectedLocations}
                setLatLong={setLatLong}
              />
            )}
            {searchType === 'utm' && (
              <IVFUtmFields
                locationSelectLimit={locationSelectLimit}
                setSelectedLocations={setSelectedLocations}
                selectedLocations={selectedLocations}
                setLatLong={setLatLong}
              />
            )}
          </Grid>
          {isNonStationSelected() && (
            <Typography className={classes.pointWarningText}>
              <FormattedMessage id={'non_station_selected'} />
              <Typography className={classes.textBold}>
                <FormattedMessage id={'select_station'} />
              </Typography>
            </Typography>
          )}
        </Grid>
      </div>
      <DialogContent style={{ height: '60vh' }}>
        <IVFMap
          locationSelectLimit={locationSelectLimit}
          data={filteredStations}
          lat={latLong.lat}
          long={latLong.long}
          isStation={
            selectedLocations.length
              ? selectedLocations[0].isStation
              : undefined
          }
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
          setLatLong={setLatLong}
          ivfDataByStationId={ivfDataByStationId}
        />
      </DialogContent>
    </Dialog>
  );
};

interface DialogProps {
  onClose: () => void;
}

const DialogTitle: React.FC<DialogProps> = ({ onClose }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h6">
            <FormattedMessage id="map_tooltip" />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            aria-label="Close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <Typography variant="button">
              <FormattedMessage id="dialog_close" />
            </Typography>
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
};

export default MapDialog;
