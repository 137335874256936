import React, {ChangeEvent, ReactNode} from "react"
import {FormControl, Select} from "@material-ui/core";


interface ObsPanelSelectProps {
    handleChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: ReactNode) => void
    currentValue: string
    values: JSX.Element[]
}

const ObsPanelSelect: React.FC<ObsPanelSelectProps> = ({handleChange, currentValue, values}) => {

    return (
        <FormControl fullWidth variant="outlined" size={"small"}>
            <Select
                style={{backgroundColor: "white"}}
                value={currentValue}
                onChange={handleChange}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {values}
            </Select>
        </FormControl>
    )
}

export default ObsPanelSelect