import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  Tab,
  Theme,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core';
import { IntlShape, useIntl } from 'react-intl';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import IVFGraph from './IVFGraph';
import { IVFGraphData, IVFGraphEntity } from '../../@Types/IVFGraphData';
import ObsPanelSelect from './ObsPanelSelect';
import { LocationData } from '../../@Types/LocationData';
import { useQuery } from 'urql';
import { Article, CmsResult, Helptext } from '../../cms/types';
import { getHelptext, getPageData } from '../../cms/queries';
import { InfoButton } from '../InfoButton';
import { ReactComponent as ShareIcon } from '../../images/shareIcon.svg';
import ShareUrlDialog from '../ShareUrlDialog';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { IVFClimateFactor } from './IVFClimateFactor';
import IVFTable from './IVFTable';
import { DownloadDialog, DownloadIcon } from './DownloadDialog';
import moment from 'moment';
import { capitalizeStationName } from 'utils/stringUtils';
import { utils, writeFile } from 'xlsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    obsPanel: {
      paddingTop: theme.spacing(2),
    },
    tabRoot: {
      textTransform: 'initial',
      minWidth: 72,
      width: '100%',
      marginRight: theme.spacing(4),
    },
    tabSelected: {
      border: '1px solid #979797',
      backgroundColor: 'white',
    },
    chartContainer: {
      paddingTop: 24,
      paddingBottom: 24,
      marginTop: '15px',
    },
    tabWrapper: {
      borderTop: '1px solid #979797',
      borderBottom: '1px solid #979797',
      marginTop: '-26px',
      backgroundColor: '#F3F9F5',
    },
    obsSelect: {
      paddingBottom: theme.spacing(2),
    },
    tabPanel: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    panelIcons: {
      width: 25,
      height: 'auto',
      paddingRight: theme.spacing(),
      display: 'inline-block',
    },
    expandIcon: {
      verticalAlign: 'bottom',
    },
    climateFactorButton: {
      backgroundColor: '#FFF',
      border: '1px solid #979797',
      '&:hover': {
        backgroundColor: '#FFF',
      },
    },
    obsPanelFooter: {
      marginLeft: theme.spacing(2),
    },
    climateFactor: {
      paddingTop: theme.spacing(2),
    },
    gridHeader: {
      background: 'white',
    },
  }),
);

interface GraphPropsIVF {
  ivfData: IVFGraphEntity | null;
  selectedLocations: LocationData[];
  unit: string;
  setUnit: Dispatch<SetStateAction<string>>;
}

const IVFObservationPanel: React.FC<GraphPropsIVF> = ({
  ivfData,
  selectedLocations,
  unit,
  setUnit,
}) => {
  const [graphData, setGraphData] = React.useState<IVFGraphEntity | null>(
    ivfData,
  );
  const [tabValue, setTabValue] = React.useState('1');
  const [duration, setDuration] = React.useState('durations_all');
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const [displayClimateFactor, setDisplayClimateFactor] = React.useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (ivfData) {
      setGraphData(getIvfValues(duration, ivfData));
    } else {
      setGraphData(null);
    }
  }, [ivfData, duration]);

  const durationValues = [
    <MenuItem value={'durations_60m'} key={'durations_60m'}>
      {intl.formatMessage({ id: 'durations_60m' })}
    </MenuItem>,
    <MenuItem value={'durations_24h'} key={'durations_24h'}>
      {intl.formatMessage({ id: 'durations_24h' })}
    </MenuItem>,
    <MenuItem value={'durations_all'} key={'durations_all'}>
      {intl.formatMessage({ id: 'durations_all' })}
    </MenuItem>,
  ];
  const unitValues = [
    <MenuItem value={'mm'} key={'mm'}>
      {intl.formatMessage({ id: 'mm' })}
    </MenuItem>,
    <MenuItem value={'l/s_Ha'} key={'l/s_Ha'}>
      {intl.formatMessage({ id: 'l/s_Ha' })}
    </MenuItem>,
  ];
  const [helperTextResult] = useQuery<CmsResult<Helptext>>(
    getHelptext({
      path:
        '/kss/hjelpetekster/seklima/ivf/om-ivf-kurve' +
        (intl.locale === 'en' ? '_' + intl.locale : ''),
    }),
  );
  const helperTextResultRawData = helperTextResult.data?.guillotine.get;
  const classes = useStyles();
  const [climateFactorResult] = useQuery<CmsResult<Article>>(
    getPageData({
      path:
        '/kss/hjelpetekster/seklima/ivf/klimapaslag' +
        (intl.locale === 'en' ? '_' + intl.locale : ''),
    }),
  );
  const climateFactorResultRawData = climateFactorResult.data?.guillotine.get;

  function handleDurationChange(event: React.ChangeEvent<{ value: unknown }>) {
    let duration = event.target.value as string;
    if (duration) {
      setDuration(duration);
    }
  }

  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const tableRef: React.RefObject<HTMLTableElement> = useRef(null);

  return (
    <Grid
      container
      alignItems={'center'}
      alignContent={'center'}
      className={classes.obsPanel}
    >
      <FormControl fullWidth={true}>
        <TabContext value={tabValue}>
          <TabList
            className={classes.tabRoot}
            centered
            onChange={(event, value) => setTabValue(value)}
            indicatorColor={'primary'}
            textColor={'primary'}
          >
            <Tab
              label={intl.formatMessage({ id: 'graph' })}
              className={classes.tabSelected}
              value="1"
            />
            <Tab
              label={intl.formatMessage({ id: 'table' })}
              className={classes.tabSelected}
              value="2"
            />
            <Tab
              label={intl.formatMessage({ id: 'table_extended' })}
              className={classes.tabSelected}
              value="3"
            />
          </TabList>
          <div className={classes.chartContainer + ' ' + classes.tabWrapper}>
            <Grid container>
              <Grid
                container
                spacing={4}
                justifyContent={'center'}
                className={classes.obsSelect}
              >
                <Grid item xs={12}>
                  {ivfData && (
                    <Typography>
                      {getQualityText(ivfData.qualityClass, intl)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={1}>
                  <></>
                </Grid>
                <Grid item xs={5} md={4}>
                  <ObsPanelSelect
                    currentValue={duration}
                    handleChange={handleDurationChange}
                    values={durationValues}
                  />
                </Grid>
                <Grid item xs={5} md={4}>
                  <ObsPanelSelect
                    currentValue={unit}
                    handleChange={(event) =>
                      event && setUnit(event.target.value as string)
                    }
                    values={unitValues}
                  />
                </Grid>
                <Grid item xs={1}>
                  {helperTextResultRawData ? (
                    <InfoButton
                      text={helperTextResultRawData.data.text.processedHtml}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <TabPanel value={'1'} className={classes.tabPanel}>
              {graphData && (
                <IVFGraph
                  ivfData={graphData}
                  selectedLocation={selectedLocations[0]}
                />
              )}
            </TabPanel>
            <TabPanel value={'2'} className={classes.tabPanel}>
              {ivfData && (
                <Grid className={classes.gridHeader}>
                  <Typography variant="subtitle1">
                    {createTitle(selectedLocations[0], intl)}
                  </Typography>
                  <Typography variant="caption">
                    {createSubtitle(ivfData, intl)}
                  </Typography>
                </Grid>
              )}
              {graphData && (
                <IVFTable
                  ivfData={getGraphEntityWithoutArea(graphData)}
                  unit={unit}
                  hasPercentiles={false}
                  tableRef={tableRef}
                />
              )}
            </TabPanel>
            <TabPanel value={'3'} className={classes.tabPanel}>
              {ivfData && (
                <Grid className={classes.gridHeader}>
                  <Typography variant="subtitle1">
                    {createTitle(selectedLocations[0], intl)}
                  </Typography>
                  <Typography variant="caption">
                    {createSubtitle(ivfData, intl)}
                  </Typography>
                </Grid>
              )}
              {graphData && (
                <IVFTable
                  ivfData={graphData}
                  unit={unit}
                  hasPercentiles={true}
                  tableRef={tableRef}
                />
              )}
            </TabPanel>
            <Grid container>
              {tabValue !== '1' && (
                <Grid item xs={2}>
                  <Button onClick={() => setShowDownloadDialog(true)}>
                    <DownloadIcon />
                    <Typography style={{ textTransform: 'none' }}>
                      {intl.formatMessage({ id: 'download_table' })}
                    </Typography>
                  </Button>
                </Grid>
              )}
              <Grid item xs={2}>
                <Button
                  className={classes.obsPanelFooter}
                  onClick={() => setShowShareDialog(true)}
                >
                  <ShareIcon className={classes.panelIcons} />
                  <Typography style={{ textTransform: 'none' }}>
                    {intl.formatMessage({ id: 'share' })}
                  </Typography>
                </Button>
              </Grid>

              <Grid item xs={8} style={{ textAlign: 'center' }}>
                <Button
                  variant={'outlined'}
                  className={classes.climateFactorButton}
                  onClick={() => setDisplayClimateFactor(!displayClimateFactor)}
                  color={'primary'}
                  disableElevation
                  size={'large'}
                >
                  {intl.formatMessage({ id: 'climate_factor' })}
                  {displayClimateFactor ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.climateFactor}
            >
              {climateFactorResultRawData && displayClimateFactor && (
                <Grid item xs={12} md={6}>
                  <IVFClimateFactor {...climateFactorResultRawData} />
                </Grid>
              )}
            </Grid>
            <ShareUrlDialog
              onClose={setShowShareDialog}
              open={showShareDialog}
            />
            <DownloadDialog
              open={showDownloadDialog}
              formats={['csv', 'ods', 'xlsx']}
              onClose={() => {
                setShowDownloadDialog(false);
              }}
              onExport={(format: string) => {
                downloadData(
                  format,
                  tableRef,
                  intl,
                  selectedLocations[0],
                  unit,
                  ivfData,
                );
              }}
            />
          </div>
        </TabContext>
      </FormControl>
    </Grid>
  );
};

export default IVFObservationPanel;

export function getIvfValues(
  duration: string,
  allIvfValues: IVFGraphEntity,
): IVFGraphEntity {
  let newIvfValues = allIvfValues;
  if (duration === 'durations_60m') {
    newIvfValues = getIvfValues2(allIvfValues, (e) => e[0] <= 60);
  } else if (duration === 'durations_24h') {
    newIvfValues = getIvfValues2(allIvfValues, (e) => e[0] >= 60);
  }

  return newIvfValues;
}

function getIvfValues2(
  ivfValues: IVFGraphEntity,
  timeFilter: (durationAndIntensity: number[]) => boolean,
): IVFGraphEntity {
  const IVFGraphData = ivfValues.IVFGraphData.reduce<IVFGraphData[]>(
    (series, values) => {
      const data = values.data.filter(timeFilter);
      return [...series, { ...values, data: data }];
    },
    [],
  );
  return {
    ...ivfValues,
    IVFGraphData,
  };
}

const getGraphEntityWithoutArea = (
  graphData: IVFGraphEntity,
): IVFGraphEntity => {
  const graphDataWithoutArea = graphData.IVFGraphData.filter(
    (data) => data.type !== 'arearange',
  );
  return {
    ...graphData,
    IVFGraphData: graphDataWithoutArea,
  };
};

export function createSubtitle(ivfData: IVFGraphEntity, intl: IntlShape) {
  const {
    firstYearOfPeriod,
    lastYearOfPeriod,
    numberOfSeasons,
    updatedAt,
  } = ivfData;
  const dataFrom = intl.formatMessage({ id: 'data_from' });
  const seasons = intl.formatMessage({ id: 'number_of_seasons' });
  let dateString = '';
  if (updatedAt) {
    const formattedDate = moment.utc(updatedAt).format('DD.MM.YYYY');
    const updated = intl.formatMessage({ id: 'updated' });
    dateString = `${updated} ${formattedDate}.`;
  }
  return `${dataFrom} ${firstYearOfPeriod} - ${lastYearOfPeriod}, ${numberOfSeasons} ${seasons} ${dateString}`;
}

export function createTitle(
  selectedLocations: LocationData,
  intl: IntlShape,
  unit?: string,
) {
  if (selectedLocations) {
    if (selectedLocations.isStation) {
      if (selectedLocations.municipality && selectedLocations.county) {
        let masl = '';
        if (selectedLocations.masl)
          masl =
            selectedLocations.masl +
            ' ' +
            intl.formatMessage({ id: 'masl' }) +
            ' ';

        const titlePrefix = intl.formatMessage(
          {
            id: 'ivf_graph_title_prefix',
          },
          { unit: unit ? `(${unit}) ` : '' },
        );
        const stationName = capitalizeStationName(selectedLocations.name);
        return `${titlePrefix} ${stationName} (${selectedLocations.id}), ${masl}`;
      }
    } else if (selectedLocations.utm) {
      return (
        intl.formatMessage({ id: 'ivf_point_graph_title_prefix' }) +
        intl.formatMessage({ id: 'utm_zone_label' }) +
        ' ' +
        selectedLocations.utm.z +
        ', ' +
        intl.formatMessage({ id: 'utm_north_label' }) +
        ' ' +
        selectedLocations.utm.n +
        ', ' +
        intl.formatMessage({ id: 'utm_east_label' }) +
        ' ' +
        selectedLocations.utm.e
      );
    } else {
      const Longlat = selectedLocations.id.split(' ');
      return (
        intl.formatMessage({ id: 'ivf_point_graph_title_prefix' }) +
        Longlat[1] +
        ' N, ' +
        Longlat[0] +
        ' E'
      );
    }
  }
  return null;
}

const getQualityText = (qualityClass: number, intl: IntlShape) => {
  let qualityClassText = '';
  switch (qualityClass) {
    case 1:
      qualityClassText = intl.formatMessage({ id: 'quality_good' });
      break;
    case 2:
      qualityClassText = intl.formatMessage({ id: 'quality_uncertain' });
      break;
    case 3:
      qualityClassText = intl.formatMessage({
        id: 'quality_very_uncertain',
      });
      break;
  }

  const qualityPrefix = intl.formatMessage({ id: 'quality_class' });

  return `${qualityPrefix}: ${qualityClassText}`;
};

const downloadData = (
  format: string,
  tableRef: React.RefObject<HTMLTableElement>,
  intl: IntlShape,
  selectedLocations: LocationData,
  unit: string,
  ivfData: IVFGraphEntity | null,
) => {
  if (!tableRef.current || !ivfData) {
    return;
  }
  const unitFixed = unit === 'l/s_Ha' ? 'l/(s*ha)' : unit;
  const title = createTitle(selectedLocations, intl, unitFixed);
  const subtitle = createSubtitle(ivfData, intl);
  const qualityText = getQualityText(ivfData.qualityClass, intl);
  const licenseText = intl.formatMessage(
    { id: 'met_data_licence' },
    { date: moment().format('DD.MM.YYYY') },
  );

  const worksheet = utils.aoa_to_sheet([[title], [subtitle], [qualityText]]);

  const isCsvExport = format === 'csv';

  if (isCsvExport) {
    utils.sheet_add_dom(worksheet, tableRef.current, {
      raw: true,
      origin: -1,
    });
  } else {
    const table = utils.table_to_sheet(tableRef.current, { raw: true });

    const tableJson: string[][] = utils.sheet_to_json(table, { header: 1 });

    const tableCleaned = tableJson.map((row) => {
      return row.map((cell) => {
        const cellAsNumber = Number(cell.replace(',', '.'));
        if (isNaN(cellAsNumber)) {
          return cell;
        }
        return cellAsNumber;
      });
    });

    utils.sheet_add_aoa(worksheet, tableCleaned, {
      origin: -1,
    });
  }

  utils.sheet_add_aoa(worksheet, [[licenseText]], { origin: -1 });

  if (isCsvExport) {
    const csv = utils.sheet_to_csv(worksheet, { FS: ';' });
    downloadFile('IVF.csv', csv);
    return;
  }

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet);
  writeFile(workbook, `IVF.${format}`);
};

// From https://stackoverflow.com/a/18197341
function downloadFile(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
