export function capitalizeWord(text: string) {
  if (typeof text !== 'string') return '';
  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function capitalizeAllWords(text: string) {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => capitalizeWord(s))
    .join(' ');
}

export function capitalizeStationName(text: string) {
  let name = capitalizeAllWords(text);
  name = name.replace(' Iiii', ' IIII');
  name = name.replace(' Iii', ' III');
  name = name.replace(' Ii', ' II');
  name = name.replace(' Viii', ' VIII');
  name = name.replace(' Vii', ' VII');
  name = name.replace(' Vi', ' VI');
  name = name.replace(' Iv', ' IV');
  name = name.replace('Rv', 'RV');
  name = name.replace('Fv', 'FV');
  return name;
}

export function filterUndefStringMap(orig: Map<string, string | undefined>) : Map<string, string> {
  let validMap = new Map<string, string>();
  orig.forEach( (value, key) => {
    if (key && value)
      validMap.set(key, value);
  });
  return validMap;
}

export function noNullString(orig: string | null, def: string): string {
  if (orig) {
    return orig;
  } else {
    return def;
  }
}
