import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Container,
  Grid,
  Link,
  Paper,
  Theme,
  Typography,
  makeStyles,
  createStyles,
  alpha,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { Menu, MenuFolder } from './types';
import { isMenuFolder } from './navigationUtils';
import { getLinkProps } from 'services/helpers';
import { useNavigationContext } from 'services/navigationContext';

const useStyles = makeStyles((theme: Theme) => {
  const button = {
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      textDecoration: 'none',
    },
  };

  const menuMaxHeight = 370;
  const menuPadding = theme.spacing(4);

  return createStyles({
    menuBar: {
      backgroundColor: theme.palette.background.default,
      position: 'relative',
    },
    menuButton: {
      ...button,
    },
    expandedMenuButton: {
      ...button,
      backgroundColor: theme.palette.primary.light,
    },
    activeMenuButton: {
      ...button,
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.8),
      },
    },
    expandedMenuContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
    },
    expandedMenu: {
      borderColor: theme.palette.primary.light,
      maxHeight: menuMaxHeight,
      padding: menuPadding,
    },
    expandedMenuTitle: {
      borderRight: `1px solid ${theme.palette.text.secondary}`,
    },
    expandedMenuLinkList: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      paddingLeft: 2 * menuPadding,
      maxHeight: menuMaxHeight - 2 * menuPadding,
      marginTop: theme.spacing(-2),
    },
    menuLinkContainer: {
      marginTop: theme.spacing(2),
    },
    menuLink: {
      fontWeight: 500,
      color: 'inherit',
    },
  });
});

export const Menus: React.FC = () => {
  const classes = useStyles();
  const { menus, activeMenuFolder } = useNavigationContext();
  const itemsRef = useRef<Array<HTMLButtonElement | null>>([]);
  const [currentMenuIndex, setCurrentMenuIndex] = React.useState<number>(-1);
  const [expandedMenu, setExpandedMenu] = useState<MenuFolder | null>(null);
  const expandedMenuRef = useRef<HTMLElement | null>(null);
  const [focusGuard, setFocusGuard] = React.useState<Element | null>(null);
  const closeMenu = useCallback(() => {
    setCurrentMenuIndex(-1);
    setExpandedMenu(null);
  }, []);

  const getMenuButtonClass = (menu: Menu) => {
    if (expandedMenu === menu) return classes.expandedMenuButton;

    if (activeMenuFolder === menu) return classes.activeMenuButton;

    return classes.menuButton;
  };

  const handleMenuFolderClicked = (menuFolder: MenuFolder, index: number) => {
    if (expandedMenu !== menuFolder) {
      setExpandedMenu(menuFolder);
      setCurrentMenuIndex(index);
      return;
    }
    closeMenu();
  };

  // Set fist menu element if focus when opening the menu
  React.useEffect(() => {
    if (expandedMenuRef && expandedMenuRef.current) {
      expandedMenuRef.current.focus();
    }
  }, [expandedMenu]);

  React.useEffect(() => {
    if (currentMenuIndex === -1) {
      return;
    }
    // Checks if we have tabbed outside the expanded menu. If so set focus to Menu element.
    if (!focusGuard) setFocusGuard(document.querySelector('#focusguard'));
    if (focusGuard)
      focusGuard.addEventListener('focus', () => {
        if (itemsRef && itemsRef.current)
          itemsRef.current[currentMenuIndex]?.focus();
        closeMenu();
        setFocusGuard(null);
      });

    // set focus to menu when escaping expaneded menu element.
    function escEventListener(event: any) {
      if (event.key === 'Escape') {
        if (itemsRef && itemsRef.current)
          itemsRef.current[currentMenuIndex]?.focus();
        closeMenu();
        setFocusGuard(null);
      }
    }

    window.addEventListener('keydown', escEventListener);
    return () => {
      window.removeEventListener('keydown', escEventListener);
    };
  }, [closeMenu, currentMenuIndex, focusGuard]);

  React.useEffect(() => {
    if (focusGuard)
      focusGuard.addEventListener('focus', () => {
        // firstItem.focus();
        if (itemsRef && itemsRef.current)
          itemsRef.current[currentMenuIndex]?.focus();
        closeMenu();
      });
  }, [closeMenu, currentMenuIndex, focusGuard]);

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Paper square className={classes.menuBar} elevation={0}>
        <Container className={classes.menuBar}>
          <Grid container item md={12} justifyContent="space-between">
            {menus.map((menu, index) =>
              isMenuFolder(menu) ? (
                <Button
                  ref={(el) => (itemsRef.current[index] = el)}
                  key={menu.name}
                  onClick={() => handleMenuFolderClicked(menu, index)}
                  className={getMenuButtonClass(menu)}
                >
                  {menu.name}
                  {expandedMenu === menu ? <ExpandLess /> : <ExpandMore />}
                </Button>
              ) : (
                <Button
                  key={menu.name}
                  {...getLinkProps(menu.path)}
                  className={classes.menuButton}
                  onClick={closeMenu}
                >
                  {menu.name}
                </Button>
              ),
            )}
          </Grid>
        </Container>
        {expandedMenu !== null && (
          <Container className={classes.expandedMenuContainer}>
            <Paper square variant="outlined" className={classes.expandedMenu}>
              <Grid container>
                <Grid item md={3} className={classes.expandedMenuTitle}>
                  <Typography variant="h3">{expandedMenu.name}</Typography>
                </Grid>
                <Grid
                  container
                  item
                  md={9}
                  className={classes.expandedMenuLinkList}
                >
                  {expandedMenu.links.map((link, index) => (
                    <Grid
                      item
                      key={link.name}
                      className={classes.menuLinkContainer}
                    >
                      <Link
                        ref={(el) =>
                          index === 0 && (expandedMenuRef.current = el)
                        }
                        tabIndex={0}
                        {...getLinkProps(link.path)}
                        className={classes.menuLink}
                        variant="body1"
                        onClick={closeMenu}
                        lang={
                          link.name ===
                            'About NCCS and links to English pages' ||
                          link.name === 'Norwegian Centre for Climate Services'
                            ? 'en'
                            : 'nb'
                        }
                      >
                        {link.name}
                      </Link>
                    </Grid>
                  ))}
                  <div id="focusguard" tabIndex={0} />
                </Grid>
              </Grid>
            </Paper>
          </Container>
        )}
      </Paper>
    </ClickAwayListener>
  );
};
