export const fetchConfig = async () => {
  try {
    const response = await fetch('/' + encodeURI('frontend.properties'));
    const data = await response.text();
    return data
      .split('\n')
      .filter((line: string) => line.trim().length) // removes empty lines
      .reduce((currentMap: Map<string, string>, line: string) => {
        const separatorIndex = line.indexOf('=');
        return currentMap.set(
          line.substring(0, separatorIndex),
          line.substring(separatorIndex + 1),
        );
      }, new Map<string, string>());
  } catch (error) {
    console.log(error);
  }
  return new Map<string, string>();
};
