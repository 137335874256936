import React, { useState } from 'react';
import {
  AppBar,
  Container,
  Toolbar,
  Grid,
  Hidden,
  Button,
  Box,
} from '@material-ui/core';
import { SearchBar } from './SearchBar';
import { KssLogoLink } from './Links';
import { LanguageSelector } from './LanguageSelector';
import { Menus, MobileMenus } from 'components/navigation';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export const headerHeight = {
  xs: 56,
  sm: 64,
  md: 100,
};

export const Header: React.FC = () => {
  const location = useLocation();

  return (
    <AppBar position="fixed">
      <JumpToMainContent />
      <Toolbar>
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={10} md={5}>
              <KssLogoLink
                url="/"
                id="logoKss"
                alt="KSS logo"
                isInHeader={true}
              />
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-end"
              alignItems="center"
              xs={2}
              md={7}
            >
              <Hidden smDown>
                <SearchBar isInHeader={true} />
                <div
                  style={{
                    display: location.pathname === '/ivf' ? 'inherit' : 'none',
                  }}
                >
                  <LanguageSelector />
                </div>
              </Hidden>
              <Hidden mdUp>
                <MobileMenus />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
      <Hidden smDown>
        <Menus />
      </Hidden>
    </AppBar>
  );
};

const JumpToMainContent: React.FC = () => {
  const [showButton, setShowButton] = useState(false);

  return (
    <Box
      style={{
        position: 'absolute',
        zIndex: 1,
      }}
    >
      {!showButton ? (
        <Box
          tabIndex={0}
          onFocus={() => {
            setShowButton(true);
          }}
        />
      ) : (
        <>
          <Button
            ref={(ref) => {
              ref?.focus();
            }}
            onBlur={() => {
              setShowButton(false);
            }}
            href="#main-content"
            variant="contained"
            color="secondary"
          >
            <FormattedMessage id="jump_to_main_content" />
          </Button>
        </>
      )}
    </Box>
  );
};
