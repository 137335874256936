import React, { useContext } from 'react';
import { Article } from 'cms/types';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeSlug } from '../../services/helpers';
import { headerHeight } from '../Header';
import { useHistory } from 'react-router-dom';
import { APIContext } from 'utils/contextUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerHr: {
      border: '0px',
      height: '0.5px',
      backgroundColor: '#4A4A4A;',
    },
    imageDescription: {
      '& p': {
        display: 'inline',
      },
    },
    articleSection: {
      '& img:not($articleImage)': {
        width: '100%',
      },
      '& + &': {
        marginTop: theme.spacing(4),
      },
    },
    articleImageContainer: {
      maxWidth: '400px',
    },
    articleAnchor: {
      paddingTop: headerHeight.xs,
      marginTop: -1 * headerHeight.xs,
      [theme.breakpoints.up('sm')]: {
        paddingTop: headerHeight.sm,
        marginTop: -1 * headerHeight.sm,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: headerHeight.md,
        marginTop: -1 * headerHeight.md,
      },
    },
    articleImage: {
      width: '100%',
    },
    factbox: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  }),
);

export const IVFClimateFactor: React.FC<Article> = ({
  data,
  ...props
}: Article) => {
  const classes = useStyles();
  const articleSections = data.article ? data.article : [];
  const history = useHistory();
  const configContext = useContext(APIContext);
  const REACT_APP_GRAPHQL_LINK_ROOT = configContext.get(
    'REACT_APP_GRAPHQL_LINK_ROOT',
  );
  const handlePossibleLinkClick = (event: any) => {
    if (event.target.tagName !== 'A') return;
    const element: HTMLAnchorElement = event.target;
    if (element.href.includes('download')) return;

    const linkRoot = REACT_APP_GRAPHQL_LINK_ROOT ?? '';

    if (linkRoot !== '' && element.href.includes(linkRoot)) {
      event.preventDefault();
      //we needed to add kss becuase linkroot messed with met urls.
      const pageLink = '/kss' + element.href.split(linkRoot)[1];
      history.push(pageLink);
    }
  };

  return (
    <>
      <Typography variant="h1">{props.displayName}</Typography>
      <hr className={classes.headerHr} />
      {articleSections.map((section, articleIndex) => (
        <Box
          key={`article-${articleIndex}`}
          className={classes.articleSection}
          onClick={(event) => handlePossibleLinkClick(event)}
        >
          {section.blockHeading && (
            <Typography
              variant="h2"
              id={makeSlug(section.blockHeading)}
              className={classes.articleAnchor}
            >
              {section.blockHeading.trim()}
            </Typography>
          )}
          <Typography
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: section.article?.processedHtml }}
          />
          {section.images.length > 0 && (
            <Grid container spacing={4}>
              {section.images.map(
                (image, imageIndex) =>
                  image.image && (
                    <Grid
                      item
                      xs={12}
                      sm={section.images.length > 1 ? 6 : 8}
                      key={`articleImage-${articleIndex}-${imageIndex}`}
                    >
                      <Box className={classes.articleImageContainer}>
                        <img
                          src={image.image.imageUrl}
                          alt={image.image.data.caption}
                          className={classes.articleImage}
                        />
                        <Typography
                          variant="caption"
                          className={classes.imageDescription}
                          dangerouslySetInnerHTML={{
                            __html: image.text?.processedHtml,
                          }}
                        />
                        {image.image.data.artist.length > 0 && (
                          <Typography variant="caption">
                            {`Kilde: ${image.image.data.artist.join(', ')}.`}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  ),
              )}
            </Grid>
          )}
          {section.factbox.length > 0 &&
            section.factbox.map((fb, fbIndex) => (
              <Typography
                variant="body1"
                className={classes.factbox}
                component="div"
                dangerouslySetInnerHTML={{ __html: fb.content?.processedHtml }}
                key={`factbox-${articleIndex}-${fbIndex}`}
              />
            ))}
        </Box>
      ))}
    </>
  );
};
