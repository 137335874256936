import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IVFGraphEntity, IVFGraphData } from '../../@Types/IVFGraphData';
import { useIntl } from 'react-intl';
import { createStyles, Theme } from '@material-ui/core';
import { sortBy } from 'lodash';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableHead: {
      textAlign: 'left',
    },
  }),
);

interface IVFTableProps {
  ivfData: IVFGraphEntity;
  unit: string;
  hasPercentiles: boolean;
  tableRef: React.RefObject<HTMLTableElement>;
}

const IVFTable: React.FC<IVFTableProps> = ({
  ivfData,
  unit,
  hasPercentiles,
  tableRef,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [uniqueDurations, setUniqueDurations] = React.useState<number[]>([]);

  useEffect(() => {
    setUniqueDurations(getTableDurations(ivfData));
  }, [ivfData]);

  function getTableDurations(ivfData: IVFGraphEntity) {
    let unique = [];

    for (let dataRow of ivfData.IVFGraphData) {
      for (let item of dataRow.data) {
        if (unique.indexOf(item[0]) === -1) {
          unique.push(item[0]);
        }
      }
    }

    return unique;
  }

  const getLocalizedNumber = (num: number) => {
    if (intl.locale === 'nb') {
      return num.toFixed(1).replace('.', ',');
    } else {
      return num.toFixed(1);
    }
  };

  function createRow(IVFRow: RowData) {
    let row = [];
    for (let duration of uniqueDurations) {
      let durationExists = false;
      for (let value of IVFRow.data) {
        if (value[0] === duration) {
          row.push(
            <TableCell key={IVFRow.frequency + '_cell_' + value[1]}>
              {getLocalizedNumber(value[1])}
            </TableCell>,
          );
          durationExists = true;
        }
      }
      if (!durationExists) {
        row.push(
          <TableCell key={'empy_duration_' + duration}>{'-'}</TableCell>,
        );
      }
    }
    return row;
  }

  const rowData = convertGraphDataToTableData(ivfData.IVFGraphData);
  let rowColorToggle = true;

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} ref={tableRef}>
          <TableHead>
            <TableRow style={{ backgroundColor: 'white' }}>
              <TableCell
                colSpan={1}
                style={{ borderRight: 'none' }}
              ></TableCell>
              {hasPercentiles && (
                <TableCell
                  colSpan={1}
                  style={{ borderLeft: 'none' }}
                ></TableCell>
              )}
              <TableCell
                className={classes.tableHead}
                colSpan={uniqueDurations.length}
              >
                {intl.formatMessage({ id: 'durations_label' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {intl.formatMessage({ id: 'return_period' })}
              </TableCell>
              {hasPercentiles && (
                <TableCell>
                  {intl.formatMessage({ id: 'percentiles' })}
                </TableCell>
              )}
              {uniqueDurations.map((duration, durationIndex) => (
                <TableCell
                  key={durationIndex + 'duration'}
                  style={{ fontWeight: 'bold' }}
                >
                  {duration}
                </TableCell>
              ))}
            </TableRow>
            {rowData.map((row, index) => {
              if (index % 3 === 0) {
                rowColorToggle = !rowColorToggle;
              }
              const style = {
                backgroundColor: rowColorToggle ? '#f3f9f5' : 'white',
              };

              let percentileText = '';
              // If percentile doesn't have decimals no need to localize it
              if (row.percentile % 1 === 0) {
                percentileText = row.percentile.toString();
              } else {
                percentileText = getLocalizedNumber(row.percentile);
              }
              return (
                <TableRow
                  key={`row_${row.frequency}_${row.percentile}`}
                  style={hasPercentiles ? style : undefined}
                >
                  <TableCell key={index + 'frequency'}>
                    {row.frequency}
                  </TableCell>
                  {hasPercentiles && <TableCell>{percentileText}</TableCell>}
                  {createRow(row)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default IVFTable;

export interface RowData {
  percentile: number;
  frequency: number;
  data: number[][];
}

export const convertGraphDataToTableData = (
  IVFGraphData: IVFGraphData[],
): RowData[] => {
  const rowData = IVFGraphData.reduce<RowData[]>((rowData, graphData) => {
    const { frequency, data, type } = graphData;
    if (type === 'line') {
      const row = { frequency, data, percentile: 50 };
      return [...rowData, row];
    } else {
      const lowData = data.map(([duration, low, _high]) => [duration, low]);
      const highData = data.map(([duration, _low, high]) => [duration, high]);

      return [
        ...rowData,
        { frequency, data: lowData, percentile: 2.5 },
        { frequency, data: highData, percentile: 97.5 },
      ];
    }
  }, []);

  const sortedRowData = sortBy(rowData, ['frequency', 'percentile']);
  return sortedRowData;
};
