import React, { ChangeEvent, ReactNode } from 'react';
import {
  FormControl,
  Select,
  Typography,
  MenuItem,
  Box,
} from '@material-ui/core';
import { useQuery } from 'urql';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { getHelptext } from 'cms/queries';
import { CmsResult, Helptext } from 'cms/types';
import { InfoButton } from 'components/InfoButton';

interface Props {
  title: string;
  options: Map<string, string>;
  selectedValue: string;
  helpTextPath: string;
  paramChanged: (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: ReactNode,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectForm: {
      width: '100%',
    },
    selectMenu: {
      width: '100%',
    },
  }),
);

const ScenarioParameterSection: React.FC<Props> = ({
  title,
  options,
  selectedValue,
  helpTextPath,
  paramChanged,
}) => {
  const classes = useStyles();

  const [result] = useQuery<CmsResult<Helptext>>(
    getHelptext({
      path: helpTextPath,
    }),
  );
  const helpTextRawData = result.data?.guillotine.get;

  let climateIndexItems = new Array<JSX.Element>();

  options.forEach((value, key) => {
    climateIndexItems.push(
      <MenuItem value={key} key={key}>
        {value}
      </MenuItem>,
    );
  });

  return (
    <Box my={1}>
      <Typography component="div" variant="body1">
        <FormattedMessage id={title} />
        {helpTextRawData && (
          <InfoButton text={helpTextRawData.data.text.processedHtml} />
        )}
      </Typography>
      <FormControl variant="outlined" className={classes.selectForm}>
        <Select
          value={selectedValue}
          className={classes.selectMenu}
          onChange={paramChanged}
        >
          {climateIndexItems}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ScenarioParameterSection;
