/* eslint-disable */
import React from 'react';
import { injectIntl } from 'react-intl';
import Map from 'ol/Map';
import { Tile } from 'ol/layer';
import View from 'ol/View';
import { boundingExtent } from 'ol/extent';
import Overlay from 'ol/Overlay';
import TileWMS from 'ol/source/TileWMS';
import { WMTS, Cluster } from 'ol/source';
import { Zoom } from 'ol/control';
import TilegridWMTS from 'ol/tilegrid/WMTS';
import { get as getProjection, transform } from 'ol/proj.js';
import { getWidth, getTopLeft } from 'ol/extent.js';
import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import PropTypes from 'prop-types';
import './IVFMap.css';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import LocationMarkerIco from '../../images/sted_lilla_ico.png';
import LocationMarker from '../../images/sted_lilla.png';
import StationDefaultMarker from '../../images/mapIconStation.png';
import { capitalizeStationName } from '../../utils/stringUtils';
import StationSelectedMarker from '../../images/mapIconStationSelected.png';
import ClusterSelectedMarker from '../../images/mapIconClusterSelected.png';
import ClusterMarker from '../../images/mapIconCluster.png';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Control from 'ol/control/Control';
import Attribution from 'ol/control/Attribution';
import 'ol/ol.css';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { toLonLat } from 'ol/proj';
import { IntlShape, useIntl } from 'react-intl';

const styles = (theme) => ({
  closeButton: {
    right: 50,
    top: 50,
    position: 'absolute',
    color: theme.palette.grey[500],
  },
});

const iconStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ {
      src: LocationMarker,
      scale: 0.5,
    },
  ),
});

class MapComponent extends React.Component {
  state = {
    map: {},
    locationLayer: {},
    stationLayer: {},
    sProjection: 'EPSG:32633',
    baseZoom: 3,
    closeZoom: 7,
    selectionDesc: null,
    stations: [],
  };

  componentDidUpdate(prevProps) {
    if (
      (this.props.lat !== prevProps.lat ||
        this.props.long !== prevProps.long) &&
      this.props.lat !== 0 &&
      this.props.long !== 0 &&
      this.props.isStation !== undefined
    ) {
      this.handleLocationChange(
        this.props.lat,
        this.props.long,
        this.props.isStation,
      );
    }
    if (this.props.selectedLocations !== prevProps.selectedLocations) {
      this.handleSelectedStationsChange(this.props.selectedLocations);
    }
  }

  iconFeature = (lat, long) => {
    return new Feature({
      geometry: new Point(fromLonLat([long, lat], this.state.sProjection)),
    });
  };

  flyTo(view, location) {
    const duration = 2000;
    let parts = 2;
    let called = false;
    function callback(complete) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
      }
    }
    view.animate(
      {
        center: location,
        duration: duration,
      },
      callback,
    );
    view.animate(
      {
        zoom: this.state.closeZoom / 2,
        duration: duration / 2,
      },
      {
        zoom: this.state.closeZoom,
        duration: duration / 2,
      },
      callback,
    );
  }

  handleLocationChange = (lat, long, isStation) => {
    if (isStation) {
      this.flyTo(
        this.state.map.getView(),
        fromLonLat([long, lat], this.state.sProjection),
      );
      return;
    }
    const icon = this.iconFeature(lat, long);
    icon.setStyle(iconStyle);
    const vectorSource = new VectorSource({
      features: [icon],
    });
    if (Object.entries(this.state.locationLayer).length) {
      this.state.locationLayer.getSource().clear();
      this.state.locationLayer.setSource(vectorSource);
      this.flyTo(
        this.state.map.getView(),
        fromLonLat([long, lat], this.state.sProjection),
        function () {},
      );
    } else {
      this.flyTo(
        this.state.map.getView(),
        fromLonLat([long, lat], this.state.sProjection),
        function () {},
      );
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      this.setState({ locationLayer: vectorLayer });
      this.state.map.addLayer(vectorLayer);
    }
  };

  handleSetLocation = (lat, long) => {
    let id = long.toFixed(2) + ' ' + lat.toFixed(2);
    let location = {
      name: 'lat: ' + lat.toFixed(2) + ' long: ' + long.toFixed(2),
      id: id,
      isStation: false,
    };
    this.props.setSelectedLocations(
      this.props.selectedLocations.concat(location),
    );
  };

  handleSelectedStationsChange = (selectedLocations) => {
    const selectedIds = Object.values(selectedLocations).map((item) => item.id);
    if (this.state.selectionDesc)
      this.updateSelectionDesc(selectedIds.length, this.state.selectionDesc);
    const clusters = this.state.stationLayer.getSource().getFeatures();
    for (const cIndex in clusters) {
      const features = clusters[cIndex].getProperties().features;
      for (const fIndex in features) {
        const selected = selectedIds.includes(
          features[fIndex].get('stationId'),
        );
        if (selected) {
          features[fIndex].setProperties({ selected: selected });
        } else if (features[fIndex].get('selected')) {
          // unselect feature
          features[fIndex].setProperties({ selected: false });
        }
      }
    }
  };

  handleRemoveLocation = () => {
    //TODO this does not work for multiple locations
    this.props.setSelectedLocations([]);
    this.handleSelectedStationsChange([]);
    if (Object.entries(this.state.locationLayer).length)
      this.state.locationLayer.getSource().clear();
  };

  getQualityText = (qualityClass) => {
    let qualityClassText = '';
    switch (qualityClass) {
      case 1:
        qualityClassText = this.props.intl.formatMessage({
          id: 'quality_good',
        });
        break;
      case 2:
        qualityClassText = this.props.intl.formatMessage({
          id: 'quality_uncertain',
        });
        break;
      case 3:
        qualityClassText = this.props.intl.formatMessage({
          id: 'quality_very_uncertain',
        });
        break;
    }
    qualityClassText = qualityClassText.replace(/\s*\(\d+\)$/, '');

    return qualityClassText;
  };

  addFeatureLayer = (map, selectedLocations) => {
    const selectedIds = Object.values(selectedLocations).map((item) => item.id);
    const features = [];
    const ivfDataByStationId = this.props.ivfDataByStationId || {};
    for (const index in this.props.data) {
      if (
        this.props.data[index] &&
        this.props.data[index].geometry &&
        this.props.data[index].geometry.coordinates
      ) {
        const maslStr = this.props.intl.formatMessage({ id: 'masl_no_dot' });
        const firstYearOfPeriod = this.props.data[index].firstYearOfPeriod;
        const LastYearOfPeriod = this.props.data[index].LastYearOfPeriod;
        let validDates = '';
        if (firstYearOfPeriod && LastYearOfPeriod) {
          validDates = firstYearOfPeriod + ' - ' + LastYearOfPeriod + ', ';
        }
        const municipality = this.props.data[index].municipality
          ? this.props.data[index].municipality
          : '';
        const county = this.props.data[index].county
          ? this.props.data[index].county
          : '';
        const ivfData = ivfDataByStationId[this.props.data[index].id];
        const qualityText = ivfData
          ? `<br>${this.getQualityText(ivfData.qualityClass)}`
          : '';
        let htmlDescription =
          `<strong>${capitalizeStationName(
            this.props.data[index].name,
          )}</strong><br>` +
          `${capitalizeStationName(municipality)} ` +
          `${this.props.data[index].masl} ${maslStr}` +
          `${qualityText}`;
        const selected = selectedIds.includes(this.props.data[index].id);
        const f = new Feature({
          geometry: new Point(
            fromLonLat(
              this.props.data[index].geometry.coordinates,
              this.state.sProjection,
            ),
          ),
          stationId: this.props.data[index].id,
          stationName: this.props.data[index].name,
          municipality: municipality,
          county: county,
          selected: selected,
          htmlDescription: htmlDescription,
        });
        features.push(f);
      }
    }
    const stationVectorSource = new VectorSource({
      features: features,
    });
    const stationSource = new Cluster({
      distance: 20,
      source: stationVectorSource,
    });
    const stationLayer = new VectorLayer({
      source: stationSource,
      style: this.stationFeatureStyle,
    });
    map.addLayer(stationLayer);
    this.addFeatureEvents(map);
    this.setState({ stationLayer: stationLayer });

    for (let index in selectedLocations) {
      //TODO needs to be optimized for multiple locations
      if (!selectedLocations[index].isStation) {
        let latLong = selectedLocations[index].id.split(' ');
        const icon = this.iconFeature(
          parseFloat(latLong[1]),
          parseFloat(latLong[0]),
        );
        icon.setStyle(iconStyle);
        const vectorSource = new VectorSource({
          features: [icon],
        });
        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });
        this.setState({ locationLayer: vectorLayer });
        map.addLayer(vectorLayer);
      }
    }
  };

  addFeatureEvents = (map) => {
    const element = document.getElementById('featureDescription');
    const popup = new Overlay({
      element: element,
      positioning: 'bottom-center',
      stopEvent: false,
      offset: [0, -20],
    });
    map.addOverlay(popup);
    const intl = this.props.intl; // to access intl inside anonymous function
    map.on('pointermove', function (event) {
      // show mouse-over popup
      const features = map.getFeaturesAtPixel(event.pixel, { hitTolerance: 2 });
      if (features) {
        features.forEach(function (feature) {
          if (feature && feature.getProperties().features) {
            element.style.display = 'block';
            const coordinates = feature.getGeometry().getCoordinates();
            popup.setPosition(coordinates);
            if (feature.getProperties().features.length === 1) {
              element.innerHTML = feature
                .getProperties()
                .features[0].get('htmlDescription');
            } else if (feature.getProperties().features.length > 1) {
              const stationStr = intl.formatMessage({ id: 'stations' });
              element.innerHTML =
                '<strong>' +
                feature.getProperties().features.length +
                ' ' +
                stationStr +
                '</strong>';
            }
          }
        });
      } else {
        element.style.display = 'none';
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const innerThis = this;
    map.on('singleclick', function (event) {
      if (innerThis.props.selectedLocations.length > 0) {
        innerThis.handleRemoveLocation(); //TODO since we at the moment only have one location, we just remove the other selected location/station
      }
      const clusters = map.getFeaturesAtPixel(event.pixel, { hitTolerance: 2 });
      if (clusters && clusters.length > 0) {
        clusters.forEach(function (cluster) {
          if (cluster && cluster.getProperties().features) {
            const features = cluster.getProperties().features;
            if (features.length === 1) {
              //toggle station
              innerThis.handleSelectStation(
                features[0].get('stationId'),
                features[0].get('stationName'),
                features[0].get('selected'),
                features[0].get('county'),
                features[0].get('municipality'),
              );
            } else {
              // zoom in on cluster
              const extents = [];
              features.forEach(function (f) {
                extents.push(f.getGeometry().flatCoordinates);
              });
              const newExtent = boundingExtent(extents);
              map.getView().fit(newExtent, {
                size: map.getSize(),
                padding: [50, 10, 20, 10],
                duration: 400,
              });
            }
          } else {
            innerThis.handleRemoveLocation();
            innerThis.props.setLatLong({ lat: 0, long: 0 });
          }
        });
      } else {
        element.style.display = 'none';
        if (
          innerThis.props.locationSelectLimit <
          innerThis.props.selectedLocations.length
        ) {
          this.state.locationLayer.getSource().clear();
        }
        let lonLat = toLonLat(event.coordinate, innerThis.state.sProjection);
        innerThis.handleSetLocation(lonLat[1], lonLat[0]);
        innerThis.handleLocationChange(lonLat[1], lonLat[0], false);
        innerThis.updateSelectionDesc(
          innerThis.state.selectedLocations
            ? this.state.selectedLocations.length
            : 1,
          innerThis.state.selectionDesc,
        );
      }
    });
  };

  handleSelectStation = (
    stationId,
    stationName,
    selected,
    county,
    municipality,
  ) => {
    if (
      !selected &&
      Object.keys(this.props.selectedLocations).length >=
        this.props.locationSelectLimit
    ) {
      return; // not allowed to select more than stationSelectLimit
    }
    //const newSelectedStations = Object.assign({}, this.props.selectedStations);
    if (!selected) {
      let location = {
        name: capitalizeStationName(stationName),
        id: stationId,
        isStation: true,
        county: county,
        municipality: municipality,
      };
      this.props.setSelectedLocations(
        this.props.selectedLocations.concat([location]),
      );
    } else if (this.props.selectedLocations.some((el) => el.id === stationId)) {
      let tmp = this.props.selectedLocations.filter(
        (el) => el.id !== stationId,
      );
      this.props.setSelectedLocations(tmp);
    }
    this.handleSelectedStationsChange(this.props.selectedLocations);
  };

  stationFeatureStyle(feature) {
    let style = null;
    const features = feature.getProperties().features;
    if (features.length > 1) {
      let isSelected = false;
      for (const i in features) {
        if (features[i].get('selected')) {
          isSelected = true;
          break;
        }
      }
      const marker = isSelected ? ClusterSelectedMarker : ClusterMarker;
      style = new Style({
        image: new Icon({
          src: marker,
        }),
      });
    } else {
      const isSelected = features[0].get('selected');
      const marker = isSelected ? StationSelectedMarker : StationDefaultMarker;
      style = new Style({
        image: new Icon({
          src: marker,
        }),
      });
    }
    return style;
  }

  getMapHelpContent = (intl) => {
    let mapHelp = '<p>' + intl.formatMessage({ id: 'map_help_text' }) + '</p>';
    mapHelp += '<p>' + intl.formatMessage({ id: 'symbols' }) + ':<br/>';
    mapHelp +=
      "<img class='symbolDescImg' src='" +
      LocationMarkerIco +
      "' ><span class='symbolDescText'>" +
      intl.formatMessage({ id: 'location_search' }) +
      '</span><br/>';
    mapHelp +=
      "<img class='symbolDescImg' src='" +
      StationDefaultMarker +
      "' ><span class='symbolDescText'>" +
      intl.formatMessage({ id: 'station' }) +
      '</span><br/>';
    mapHelp +=
      "<img class='symbolDescImg' src='" +
      ClusterMarker +
      "' ><span class='symbolDescText'>" +
      intl.formatMessage({ id: 'multiple_stations' }) +
      '</span><br/>';
    mapHelp +=
      "<img class='symbolDescImg' src='" +
      StationSelectedMarker +
      "' ><span class='symbolDescText'>" +
      intl.formatMessage({ id: 'selected_station' }) +
      '</span><br/>';
    mapHelp +=
      "<img class='symbolDescImg' src='" +
      ClusterSelectedMarker +
      "' ><span class='symbolDescText'>" +
      intl.formatMessage({ id: 'one_or_more_selected' }) +
      '</span></p>';
    return mapHelp;
  };

  componentDidMount() {
    proj4.defs(
      'EPSG:32633',
      '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs ',
    );
    register(proj4);
    const sProjection = this.state.sProjection;
    const projection = getProjection(sProjection);
    const extent = [-2500000, 3500000, 3045984, 9045984];
    projection.setExtent(extent);

    const size = getWidth(extent) / 256;
    const resolutions = [],
      matrixIds = [];
    for (let z = 0; z < 21; ++z) {
      //Max 18?
      resolutions[z] = size / Math.pow(2, z);
      matrixIds[z] = sProjection + ':' + z;
    }

    const worldLayer = new Tile({
      title: 'Verden',
      type: 'base',
      source: new TileWMS({
        url: 'https://public-wms.met.no/verportal/verportal.map?bgcolor=0xF5F5F5',
        params: {
          VERSION: '1.1.1',
          LAYERS: 'kart',
          FORMAT: 'image/png',
          TRANSPARENT: false,
        },
        attributions: this.getMapHelpContent(this.props.intl),
      }),
    });

    const norwayLayerAttr = this.props.intl.formatMessage({
      id: 'map_attribution_nma',
    });
    const norwayLayer = new Tile({
      title: 'Norgeskart',
      extent: projection.getExtent(),
      source: new WMTS({
        attributions: norwayLayerAttr,
        url: 'https://opencache.statkart.no/gatekeeper/gk/gk.open_wmts?',
        layer: 'topo4graatone',
        matrixSet: sProjection,
        format: 'image/png',
        projection: projection,
        tileGrid: new TilegridWMTS({
          origin: getTopLeft(projection.getExtent()),
          resolutions: resolutions,
          matrixIds: matrixIds,
        }),
        style: 'default',
      }),
    });

    const attribution = new Attribution({
      collapsible: true,
      tipLabel: this.props.intl.formatMessage({ id: 'about_map' }),
    });

    const selectionDesc = document.createElement('p');
    const map = new Map({
      target: 'mapContainer',
      layers: [worldLayer, norwayLayer],
      controls: [
        new Zoom(),
        this.getSelectionController(selectionDesc),
        attribution,
      ],
      view: new View({
        projection: projection,
        center: transform([12, 64], 'EPSG:4326', sProjection),
        zoom: this.state.baseZoom,
      }),
    });
    this.addFeatureLayer(map, this.props.selectedLocations);
    this.updateSelectionDesc(
      this.props.selectedLocations.length,
      selectionDesc,
    );
    this.setState({ map: map, selectionDesc: selectionDesc });
  }

  updateSelectionDesc = (
    count,
    element,
    max = this.props.locationSelectLimit,
  ) => {
    const text = this.props.intl.formatMessage(
      { id: 'stations_selected' },
      { x: count, y: max },
    );
    const isAlert = element.parentElement.className.includes('alert-panel');
    if (!isAlert && count >= max) {
      // add alert style if max elements
      element.parentElement.className += ' alert-panel';
    }
    if (isAlert && count < max) {
      // remove alert style if less than max elements
      element.parentElement.className = element.parentElement.className.replace(
        ' alert-panel',
        '',
      );
    }
    element.innerHTML = text;
  };

  getSelectionController(descElement) {
    const element = document.createElement('div');
    element.className =
      'rotate-north ol-unselectable ol-control selection-panel';
    element.appendChild(descElement);
    return new Control({
      element: element,
    });
  }

  render() {
    return (
      <div id="mapContainer" className={'map-container'}>
        <div id="featureDescription" className={'station-description'} />
      </div>
    );
  }
}

MapComponent.propTypes = {
  data: PropTypes.array,
  lat: PropTypes.number,
  long: PropTypes.number,
  isStation: PropTypes.bool,
  selectedLocations: PropTypes.array.isRequired,
  setSelectedLocations: PropTypes.func.isRequired,
  locationSelectLimit: PropTypes.number.isRequired,
  setLatLong: PropTypes.func.isRequired,
};
const IVFMap = compose(withStyles(styles))(injectIntl(MapComponent));

export default IVFMap;
