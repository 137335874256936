import React, { useContext } from 'react';
import { useMount } from 'react-use';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { Article, ArticleSectionData } from 'cms/types';
import { ArticleSection } from 'components/ArticleSection';
import { TableOfContents } from 'components/TableOfContents';
import { makeSlug } from 'services/helpers';
import { APIContext } from 'utils/contextUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topImageContainer: {
      marginBottom: theme.spacing(4),
    },
    lastUpdated: {
      marginBottom: theme.spacing(4),
    },
    topImage: {
      width: '100%',
    },
    imageDescription: {
      '& p': {
        display: 'inline',
      },
    },
    articlePreface: {
      fontWeight: 700,
      letterSpacing: 0.2,
      marginBottom: theme.spacing(4),
    },
    articleSection: {
      '& img': {
        width: '100%',
      },
      '& + & h2': {
        marginTop: theme.spacing(4),
      },
    },
  }),
);

export const ArticlePage: React.FC<Article> = ({ data, ...props }: Article) => {
  const classes = useStyles();
  const configContext = useContext(APIContext);
  const REACT_APP_GRAPHQL_LINK_ROOT = configContext.get(
    'REACT_APP_GRAPHQL_LINK_ROOT',
  );
  React.useEffect(() => {
    document.title = props.displayName + ' - Norsk klimaservicesenter';
    props.displayName === 'Norwegian Centre for Climate Services' ||
    props.displayName === 'About NCCS and links to English pages'
      ? (document.documentElement.lang = 'en')
      : (document.documentElement.lang = 'nb');
  }, [props.displayName]);
  /*
    If there are any reusable blocks in the article ("external article block")
    they should be inserted as regular sections and appear in the table of content
  */
  const articleSections = (data.article ? data.article : [])
    .map((section) =>
      section.externalArticleBlock.length > 0
        ? [section, ...section.externalArticleBlock.map((ext) => ext.data)]
        : (section as ArticleSectionData),
    )
    .flat();

  // Only include sections in table of content if they have a heading
  const chapters = articleSections
    .filter((a) => a.blockHeading !== undefined && a.blockHeading !== null)
    .map((a) => ({
      anchorHref: a.blockAnchor ? a.blockAnchor : makeSlug(a.blockHeading),
      title: a.blockHeading,
    }));

  const history = useHistory();
  const hash = useLocation().hash;

  useMount(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      element?.scrollIntoView();
    }
  });

  const handlePossibleLinkClick = (event: any) => {
    if (event.target.tagName !== 'A') return;
    const element: HTMLAnchorElement = event.target;
    if (element.href.includes('download')) return;

    const linkRoot = REACT_APP_GRAPHQL_LINK_ROOT ?? '';

    if (linkRoot !== '' && element.href.includes(linkRoot)) {
      event.preventDefault();
      const pageLink = '/kss' + element.href.split(linkRoot)[1];
      history.push(pageLink);
    }
  };

  return (
    <Box paddingTop={6}>
      <Grid container>
        <Grid item xs={12} md={3}>
          {chapters.length > 0 && <TableOfContents chapters={chapters} />}
        </Grid>
        <Grid item md={1} />
        <Grid item xs={12} md={7}>
          <Typography variant="h1">{props.displayName}</Typography>
          {data.lastUpdated && (
            <Box className={classes.lastUpdated}>
              <Typography variant="caption">
                <FormattedMessage id="last_update" />
                {`: ${moment(data.lastUpdated, 'YYYY-MM-DD').format(
                  'MMMM YYYY',
                )}`}
              </Typography>
            </Box>
          )}
          {data.topimage?.image && (
            <Box
              className={classes.topImageContainer}
              onClick={(event) => handlePossibleLinkClick(event)}
            >
              <img
                className={classes.topImage}
                src={data.topimage.image.imageUrl}
                alt={data.topimage.image.data.caption}
              />
              <Typography
                variant="caption"
                className={classes.imageDescription}
                dangerouslySetInnerHTML={{
                  __html: data.topimage.text?.processedHtml,
                }}
              />
              {data.topimage.image.data?.artist.length > 0 && (
                <Typography variant="caption">
                  {` Kilde: ${data.topimage.image.data.artist.join(', ')}.`}
                </Typography>
              )}
            </Box>
          )}
          <Typography variant="body1" className={classes.articlePreface}>
            {data.preface}
          </Typography>
          {articleSections.map((section, articleIndex) => (
            <Box
              className={classes.articleSection}
              key={`article-${articleIndex}`}
              onClick={(event) => handlePossibleLinkClick(event)}
            >
              <ArticleSection sectionKey={`art${articleIndex}`} {...section} />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
