export enum ContentType {
  Article = 'no.bouvet.met.kss:Artikkel-KSS',
  Folder = 'base:folder',
  Link = 'no.bouvet.met.kss:Lenke-KSS',
  Helptext = 'no.bouvet.met.kss:Hjelpetekst-KSS',
}

export interface CmsResult<T> {
  guillotine: {
    get?: T;
    getSite?: T;
  };
}

export interface RawData<P extends ContentType | unknown = unknown> {
  _id: string;
  type: P;
  createdTime: Date;
  modifiedTime?: Date;
  displayName: string;
  pageUrl: string;
  dataAsJson: string;
  x?: {
    no_bouvet_met_kss?: {
      menu_item?: {
        menuItem: string | null;
        menuName: string | null;
      };
    };
  };
  children: RawData[];
}

export interface RawNavigationData<P extends ContentType | unknown = unknown> {
  type: P;
  children: RawData[];
}

export interface ArticleSectionData {
  blockHeading: string;
  blockAnchor: string;
  article: RichText;
  images: {
    image: ImageData;
    text: RichText;
  }[];
  factbox: { content: RichText }[];
}

interface ArticleBlock extends ArticleSectionData {
  externalArticleBlock: {
    data: ArticleSectionData;
  }[];
}

export interface Article extends RawData {
  data: {
    preface: string;
    article: ArticleBlock[];
    topimage: {
      image: ImageData;
      text: RichText;
    };
    lastUpdated: string;
  };
  type: ContentType.Article;
}

export type ImageFormat =
  | 'width(50)'
  | 'width(300)'
  | 'width(350)'
  | 'width(400)'
  | 'block(750,250)'
  | 'wide(1030,85)'
  | 'svg';

export interface ImageData {
  imageUrl?: string;
  mediaUrl?: string;
  data: {
    artist: string[];
    caption: string;
  };
}

export interface UrlData {
  internal?: {
    anchorTag?: string;
    contentKey?: {
      pageUrl: string;
    };
  };
  external?: {
    url: string;
  };
}

interface RichText {
  raw: string;
  processedHtml: string;
  macrosAsJson: string;
}

export interface Link extends RawData {
  type: ContentType.Link;
  data?: {
    text: RichText;
    image: ImageData;
    urls?: UrlData;
  };
}

export interface Helptext extends RawData {
  type: ContentType.Helptext;
  data: {
    text: RichText;
  };
}

export const isArticle = (pageData: RawData): pageData is Article => {
  return pageData.type === ContentType.Article;
};

export const isFolder = (pageData: RawData) => {
  return pageData.type === ContentType.Folder;
};

export const isLink = (pageData: RawData): pageData is Link => {
  return pageData.type === ContentType.Link;
};
