import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import 'moment/locale/nb';

import { loadStringFromLocalStorage } from 'services/localStorageService';
import * as translations from '../translations';
import { useLocation } from 'react-router-dom';

export type KSSLocale = 'en' | 'nb';

type LocalizedMessages = {
  [K in KSSLocale]: Record<string, string>;
};

const messages: LocalizedMessages = {
  nb: translations.nb,
  en: translations.en,
};

const localStorageKey = 'KSSLocale';

interface LocalizationState {
  locale: KSSLocale;
  updateLocale: (newLocale: KSSLocale) => void;
}

const initialState: LocalizationState = {
  locale: loadStringFromLocalStorage<KSSLocale>({
    key: localStorageKey,
    returnIfNull: 'nb',
  }),
  updateLocale: () => {},
};

const Context = createContext<LocalizationState>(initialState);
export const useLocalizationContext = () => useContext(Context);

export const LocalizationProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [locale, setLocale] = useState<KSSLocale>(initialState.locale);

  const updateLocale = useCallback((newLocale: KSSLocale) => {
    setLocale(newLocale);
    moment.locale(newLocale);
    localStorage.setItem(localStorageKey, newLocale);
    window.document.documentElement.lang = newLocale;
  }, []);

  useEffect(() => {
    const uriLocale = new URLSearchParams(location.search).get('locale');
    setLocale((uriLocale as KSSLocale) ?? 'nb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Context.Provider value={{ locale, updateLocale }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

LocalizationProvider.displayName = 'LocalizationProvider';
