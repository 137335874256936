import React, { useContext, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery } from 'urql';
import { Article, CmsResult } from '../cms/types';
import { getPageData } from '../cms/queries';
import { IntroText } from 'components/IntroText';
import ScenarioParameterSection from 'components/scenario/ScenarioParameterSection';
import SenarioGraph from '../components/scenario/ScenarioGraph';
import {
  getClimateIndices,
  getScenaroTimeseries,
  propertiesToRecord,
  readScenarioMapText,
  readScenarioText,
} from 'services/fetchData';
import { ClimateProjection } from '@Types/ClimateProjectionData';
import { ClimateIndex } from '@Types/ClimateIndex';
import { filterUndefStringMap, noNullString } from 'utils/stringUtils';
import { ScenarioMapSection } from 'components/scenario/ScenarioMapSection';
import { ReactComponent as ShareIcon } from '../images/shareIcon.svg';
import ShareUrlDialog from 'components/ShareUrlDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { APIContext } from 'utils/contextUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    introContainer: {
      marginBottom: theme.spacing(3),
    },
    panelIcons: {
      width: 25,
      height: 'auto',
      paddingRight: theme.spacing(),
    },
    shareButton: {
      marginLeft: 'auto',
      marginRight: theme.spacing(4),
      width: theme.spacing(12),
      display: 'flex',
    },
    shareContainer: {
      width: '100%',
      margin: theme.spacing(4),
    },
  }),
);

export const ClimateProjectionsPage: React.FC = () => {
  const styles = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const uriParams = new URLSearchParams(location.search);
  const [scenarioTS, setScenarioTS] = React.useState<ClimateProjection>();
  const [scenarioText, setScenarioText] =
    React.useState<Record<string, string>>();
  const [scenarioMapText, setScenarioMapText] =
    React.useState<Record<string, string>>();
  const [climateIndices, setClimateIndices] =
    React.useState<Array<ClimateIndex>>();

  const [selectedParams, setSelectedParams] = useState({
    index: '',
    period: '',
    scenario: '',
    area: '',
  });
  const [mapYear, setMapYear] = useState('2085');
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const configContext = useContext(APIContext);
  const REACT_APP_KSS_API_URL = configContext.get('REACT_APP_KSS_API_URL');

  React.useEffect(() => {
    document.title = 'Klimaframskrivninger - Norsk klimaservicesenter';
    document.documentElement.lang = 'nb';
  }, []);

  function getUnit(climateIndex: string) {
    if (climateIndices) {
      for (let index in climateIndices) {
        if (climateIndices[index].name === climateIndex)
          return climateIndices[index].unit;
      }
    }
    return 'Missing unit';
  }

  const scenarioOptions = new Map([
    ['RCP85', 'RCP8.5 - høyt'],
    ['RCP45', 'RCP4.5 - middels'],
  ]);

  const hasValidUrlParams = (
    params: URLSearchParams,
    indices: ClimateIndex[],
  ): boolean => {
    const index = params.get('index');
    const period = params.get('period');
    const scenario = params.get('scenario');
    const area = params.get('area');
    if (index && period && scenario && area) {
      const ci = indices.find((i) => i.name === index);
      if (ci) {
        const validPeriod = Object.keys(ci.period).includes(period);
        const validArea = Object.keys(ci.regions).includes(area);
        const validScen = 'RCP85' === scenario || 'RCP45' === scenario;
        return validPeriod && validArea && validScen;
      }
    }
    return false;
  };

  //Load climate index metadata from API and set default selectedParams after first render
  React.useEffect(() => {
    if (!REACT_APP_KSS_API_URL) return;
    getClimateIndices(REACT_APP_KSS_API_URL).then((indices) => {
      if (indices && indices.length > 0) {
        setClimateIndices(indices);
        if (hasValidUrlParams(uriParams, indices)) {
          setSelectedParams({
            index: noNullString(uriParams.get('index'), ''),
            period: noNullString(uriParams.get('period'), ''),
            scenario: noNullString(uriParams.get('scenario'), ''),
            area: noNullString(uriParams.get('area'), ''),
          });
        } else {
          setSelectedParams({
            index: indices[0].name,
            period: indices[0].period ? Object.keys(indices[0].period)[0] : '',
            scenario: 'RCP85',
            area: Object.keys(indices[0].regions)[0],
          });
        }
      }
    });
    let tmpScenarioText = propertiesToRecord(readScenarioText());
    setScenarioText(tmpScenarioText);
    let tmpMapText = propertiesToRecord(readScenarioMapText());
    setScenarioMapText(tmpMapText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      selectedParams.area &&
      selectedParams.index &&
      selectedParams.period &&
      selectedParams.scenario
    ) {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('index', selectedParams.index);
      currentUrlParams.set('period', selectedParams.period);
      currentUrlParams.set('scenario', selectedParams.scenario);
      currentUrlParams.set('area', selectedParams.area);
      history.push(
        window.location.pathname + '?' + currentUrlParams.toString(),
      );
    }
  }, [selectedParams, history]);

  //Update timeseries data when selectedParams are changed
  React.useEffect(() => {
    function cropGausData(data: ClimateProjection) {
      // Scenariodata should start earliest from year 1985 and end in 2085. Gaus filter at 15 years for observed data
      const modelStartYear = 1985;
      const observedGausfilter = 15;
      const startDiff =
        modelStartYear - Number(data.data.minMaxModelValues[0][0]);

      let minMaxModelValues: string[] = [];
      let modelValues: string[] = [];
      let obsValues: string[] = [];
      if (startDiff) {
        minMaxModelValues = data.data.minMaxModelValues.slice(
          startDiff,
          data.data.minMaxModelValues.length,
        );
        modelValues = data.data.modelValues.slice(
          startDiff,
          data.data.modelValues.length,
        );
      }
      if (observedGausfilter * 2 < data.data.obsValues.length) {
        obsValues = data.data.obsValues.slice(
          observedGausfilter,
          data.data.obsValues.length - observedGausfilter,
        );
      }
      return {
        ...data,
        data: {
          minMaxModelValues: minMaxModelValues.length
            ? minMaxModelValues
            : data.data.minMaxModelValues,
          modelValues: modelValues.length ? modelValues : data.data.modelValues,
          obsValues: obsValues.length ? obsValues : data.data.obsValues,
        },
      };
    }

    if (
      selectedParams.area &&
      selectedParams.index &&
      selectedParams.period &&
      selectedParams.scenario &&
      REACT_APP_KSS_API_URL
    ) {
      getScenaroTimeseries(
        selectedParams.index,
        selectedParams.period,
        selectedParams.area,
        selectedParams.scenario,
        REACT_APP_KSS_API_URL,
      ).then((data) => {
        if (data) {
          setScenarioTS(cropGausData(data));
        } else {
          setScenarioTS(undefined);
        }
      });
    }
  }, [selectedParams, REACT_APP_KSS_API_URL]);

  const [introTextResult] = useQuery<CmsResult<Article>>(
    getPageData({
      path: '/kss/hjelpetekster/klimaframskrivninger/klimaframskrivninger',
    }),
  );
  const introTextResultRawData = introTextResult.data?.guillotine.get;

  let indexOptions = new Map<string, string>();
  let areaOptions = new Map<string, string>();
  let periodOptions = new Map<string, string>();

  let currentIndex: ClimateIndex | undefined;

  if (
    climateIndices &&
    climateIndices.length > 0 &&
    selectedParams &&
    selectedParams.index
  ) {
    for (let ci of climateIndices) {
      indexOptions.set(ci.name, ci.label);
    }
    currentIndex = climateIndices.find((i) => i.name === selectedParams.index);
    if (currentIndex) {
      areaOptions = new Map(Object.entries(currentIndex.regions));
      periodOptions = filterUndefStringMap(
        new Map(Object.entries(currentIndex.period)),
      );
    }
  }

  const handleIndexChanged = (indexKey: string) => {
    if (climateIndices) {
      const newIndex = climateIndices.find(
        (i) => i.name === selectedParams.index,
      );
      setSelectedParams({
        ...selectedParams,
        index: indexKey,
        period: newIndex ? Object.keys(newIndex.period)[0] : '',
        area: newIndex ? Object.keys(newIndex.regions)[0] : '',
      });
    }
  };

  const handleYearChanged = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newYear: string,
  ) => {
    if (newYear !== null) {
      setMapYear(newYear);
    }
  };

  const handleAreaSelected = (areaId: string) => {
    if (areaId && areaId.length) {
      const regionId = isNaN(parseInt(areaId)) ? areaId : 'R' + areaId;
      setSelectedParams({
        ...selectedParams,
        area: regionId,
      });
    }
  };

  const graphTitle = `${indexOptions.get(
    selectedParams.index,
  )} for ${areaOptions.get(selectedParams.area)}, ${scenarioOptions.get(
    selectedParams.scenario,
  )}, for ${periodOptions.get(selectedParams.period)?.toLocaleLowerCase()}`;
  const mapHeading = `${indexOptions.get(
    selectedParams.index,
  )} for ${periodOptions
    .get(selectedParams.period)
    ?.toLocaleLowerCase()}, ${scenarioOptions.get(selectedParams.scenario)}`;
  const mapTextKey = `${
    selectedParams.index
  }.${selectedParams.period.toLowerCase()}.${selectedParams.scenario.toLowerCase()}.${mapYear}`;

  return (
    <Box paddingTop={6}>
      <Grid container spacing={2}>
        {introTextResultRawData && (
          <Grid item xs={12} className={styles.introContainer}>
            <IntroText {...introTextResultRawData} />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <ScenarioParameterSection
            title={'select_climate_index'}
            options={indexOptions}
            helpTextPath="/kss/hjelpetekster/klimaframskrivninger/velg-en-klimindeks"
            selectedValue={selectedParams.index}
            paramChanged={(e) => handleIndexChanged(String(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ScenarioParameterSection
            title={'select_climate_period'}
            options={periodOptions}
            helpTextPath="/kss/hjelpetekster/klimaframskrivninger/velg-en-periode"
            selectedValue={selectedParams.period}
            paramChanged={(e) =>
              setSelectedParams({
                ...selectedParams,
                period: String(e.target.value),
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ScenarioParameterSection
            title={'select_climate_scenario'}
            options={scenarioOptions}
            helpTextPath="/kss/hjelpetekster/klimaframskrivninger/velg-et-utslippscenario"
            selectedValue={selectedParams.scenario}
            paramChanged={(e) =>
              setSelectedParams({
                ...selectedParams,
                scenario: String(e.target.value),
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ScenarioParameterSection
            title={'select_climate_area'}
            options={areaOptions}
            helpTextPath="/kss/hjelpetekster/klimaframskrivninger/velg-et-omrade"
            selectedValue={selectedParams.area}
            paramChanged={(e) =>
              setSelectedParams({
                ...selectedParams,
                area: String(e.target.value),
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {currentIndex && (
            <ScenarioMapSection
              currentIndex={currentIndex}
              mapHeading={mapHeading}
              period={selectedParams.period}
              scenario={selectedParams.scenario}
              area={selectedParams.area}
              mapYear={mapYear}
              descriptionHTML={
                scenarioMapText ? scenarioMapText[mapTextKey] : ''
              }
              handleYearChanged={handleYearChanged}
              handleAreaSelected={handleAreaSelected}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {scenarioTS &&
          scenarioTS.data &&
          (scenarioTS.data.obsValues.length ||
            scenarioTS.data.modelValues.length) &&
          scenarioText ? (
            <SenarioGraph
              scenarioTS={scenarioTS}
              scenarioText={scenarioText}
              graphTitle={graphTitle}
              unit={getUnit(scenarioTS.metadata.climateIndex)}
            />
          ) : (
            <Typography>
              <FormattedMessage id={'missing_graph'} />
            </Typography>
          )}
          <div className={styles.shareContainer}>
            <Button
              className={styles.shareButton}
              onClick={() => setShowShareDialog(true)}
            >
              <ShareIcon className={styles.panelIcons} />
              <Typography style={{ textTransform: 'none' }}>
                {intl.formatMessage({ id: 'share' })}
              </Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
      <ShareUrlDialog onClose={setShowShareDialog} open={showShareDialog} />
    </Box>
  );
};
