import { Data } from '../@Types/StationData';

export function compareCounty(a: Data, b: Data): number {
  const countyA = a.county.toUpperCase();
  const countyB = b.county.toUpperCase();

  let comparison = 0;
  if (countyA > countyB) {
    comparison = 1;
  } else if (countyA < countyB) {
    comparison = -1;
  }
  return comparison;
}
