import React, { Dispatch, SetStateAction } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Typography } from '@material-ui/core';
import { Data } from '../../@Types/StationData';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  capitalizeAllWords,
  capitalizeStationName,
  capitalizeWord,
} from '../../utils/stringUtils';
import { LocationData } from '../../@Types/LocationData';

interface PropsIVF {
  filteredStations: Data[] | undefined;
  setLatLong?: Dispatch<SetStateAction<{ lat: number; long: number }>>;
  setSelectedLocations: Dispatch<SetStateAction<LocationData[]>>;
  selectedLocations: LocationData[];
  locationSelectLimit: number;
}

const IVFSearchField: React.FC<PropsIVF> = ({
  filteredStations,
  setLatLong,
  setSelectedLocations,
  selectedLocations,
  locationSelectLimit,
}) => {
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();

  function handleStationSelect(newValue: Data) {
    const locations: LocationData[] = [];
    if (!selectedLocations.some((station) => station.id === newValue.id)) {
      const location: LocationData = {
        name: capitalizeAllWords(newValue.name),
        id: newValue.id,
        isStation: true,
        county: newValue.county,
        municipality: newValue.municipality,
        masl: newValue.masl,
      };
      locations.push(location);

      /*
            since we at the moment only want to show one station/location. we simplify this for now.
             */
      if (setLatLong) {
        setLatLong({
          lat: newValue.geometry.coordinates[1],
          long: newValue.geometry.coordinates[0],
        });
      }
    }
    setSelectedLocations(locations);
    //setSelectedLocations(selectedLocations.concat(locations));
  }

  function handleMultipleSelect(newValue: Data[]) {
    for (let index = 0; index < newValue.length; index++) {
      handleStationSelect(newValue[index]);
    }
  }

  return (
    <Autocomplete
      id="ivfSearch"
      open={open}
      multiple
      fullWidth={true}
      value={[]}
      //disabled={selectedLocations.length === locationSelectLimit}
      closeIcon={<></>}
      renderTags={() => <></>}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      groupBy={(option) => option.county}
      onChange={(event: any, newValue: Data[] | null) => {
        newValue && handleMultipleSelect(newValue);
      }}
      getOptionLabel={(option) =>
        capitalizeStationName(option.name) +
        ' (' +
        option.id +
        ') - ' +
        capitalizeWord(option.county)
      }
      renderOption={(option) => (
        <div>
          <Typography style={{ fontWeight: 'bold' }}>
            {capitalizeStationName(option.name)} ({option.id})
          </Typography>
          <Typography>
            {capitalizeWord(option.municipality)},{' '}
            {capitalizeAllWords(option.county)}, {option.masl}{' '}
            <FormattedMessage id="masl" />
          </Typography>
        </div>
      )}
      options={filteredStations ? filteredStations : []}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={intl.formatMessage({ id: 'ivfSearchLabel' })}
          variant="outlined"
        />
      )}
    />
  );
};

export default IVFSearchField;
