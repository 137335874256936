import MuiLink from '@material-ui/core/Link';
import { HashLink } from 'react-router-hash-link';
import { UrlData } from 'cms/types';

export const makeSlug = (str: string): string => {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ /g, '-');
};

export const getUrlFromLinkContent = (urlData?: UrlData): string => {
  let url = '';

  if (urlData?.internal?.contentKey) {
    url += urlData.internal.contentKey.pageUrl;
  }

  if (urlData?.internal?.anchorTag) {
    url += `#${urlData.internal.anchorTag}`;
  }

  if (urlData?.external) {
    url = urlData.external.url;
  }

  return url;
};

export const isInternalLink = (url: string): boolean => {
  return url.startsWith('/');
};

export const getLinkProps = (path: string) =>
  isInternalLink(path)
    ? {
        component: HashLink,
        to: path,
      }
    : {
        component: MuiLink,
        href: path,
      };
