import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { UrlData } from 'cms/types';

export interface Props {
  title: string;
  content: string;
  link?: UrlData;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newsbubble: {
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        borderRadius: 15,
      },
      '&::after': {
        [theme.breakpoints.up('md')]: {
          content: '""',
          backgroundColor: theme.palette.background.default,
          height: 70,
          width: 35,
          borderLeft: `35px solid ${theme.palette.primary.light}`,
          borderBottom: `40px solid ${theme.palette.background.default}`,
          position: 'absolute',
          bottom: -40,
          left: 0,
        },
      },
      '& p:last-child': {
        marginBottom: 0,
      },
    },
  }),
);

export const Newsbubble: React.FC<Props> = ({ title, content }) => {
  const classes = useStyles();

  return (
    <Box className={classes.newsbubble}>
      <Typography variant="h3">{title}</Typography>
      <Typography
        variant="body1"
        component="div"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Box>
  );
};
