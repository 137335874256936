import React from 'react';
import { Typography } from '@material-ui/core';
import { ClimateProjection } from '@Types/ClimateProjectionData';

interface SenarioGraphTextProps {
  scenarioText: Record<string, string>;
  scenarioMetadata: ClimateProjection['metadata'];
}

const areaToScenario = (area: string): string => {
  if (area.charAt(0).toLocaleLowerCase() === 'n') return area;
  return area.charAt(0);
};

const SenarioGraphText: React.FC<SenarioGraphTextProps> = ({
  scenarioText,
  scenarioMetadata,
}) => {
  const metaDataString =
    scenarioMetadata.climateIndex +
    '.' +
    scenarioMetadata.period +
    '.' +
    scenarioMetadata.scenario +
    '.' +
    areaToScenario(scenarioMetadata.area);
  return <Typography>{scenarioText[metaDataString.toLowerCase()]}</Typography>;
};

export default SenarioGraphText;
