import { Typography } from '@material-ui/core';
import React from 'react';

export const NotFoundPage: React.FC = () => {
  React.useEffect(() => {
    document.title = 'Side ikke funnet - Norsk klimaservicesenter';
    document.documentElement.lang = 'nb';
  }, []);
  return (
    <>
      <Typography variant="h1">Fant ikke siden</Typography>
      <Typography variant="body1" paragraph={true}>
        Vi beklager, men siden du forsøker å nå finnes ikke. Prøv et søk for å
        finne det du leter etter i søkefeltet over, eller trykk tilbake i
        nettleseren for å komme tilbake til forrige side. Et klikk på "Norsk
        klimaservicesenter" bringer deg til hovedsiden.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Vær vennlig og kontakt oss via lenken nederst på siden hvis du kom til
        denne siden via lenker eller funksjoner i webportalen. Skriv gjerne ned
        feilkoden 404, hva du gjorde rett før feilen oppstod og hva slags
        nettleser og enhet (mobil, nettbrett etc.) du benyttet. På forhånd tusen
        takk for hjelpen!
      </Typography>
    </>
  );
};
