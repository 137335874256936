import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme } from '@material-ui/core/styles';

export const KSSTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: '1rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'black',
    fontSize: '14px',
    backgroundColor: '#F3F9F5',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: 400,
    maxHeight: 500,
    overflow: 'auto',
  },
}))(Tooltip);
