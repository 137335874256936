/* eslint-disable */
import React from 'react';
import { injectIntl } from 'react-intl';
import Map from 'ol/Map';
import View from 'ol/View';
import { Zoom } from 'ol/control';
import { get as getProjection, transform } from 'ol/proj.js';
import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import PropTypes from 'prop-types';
import { transformExtent } from 'ol/proj';
import { Icon, Style, Fill, Stroke } from 'ol/style';
import { Select } from 'ol/interaction';
import LocationMarker from '../../images/sted_lilla.png';
import VectorLayer from 'ol/layer/Vector';
import ImageLayer from 'ol/layer/Image';
import VectorSource from 'ol/source/Vector';
import ImageStatic from 'ol/source/ImageStatic';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import KMLFormat from 'ol/format/KML';
import 'ol/ol.css';
import './ScenarioMap.css';

const styles = (theme) => ({
  closeButton: {
    right: 50,
    top: 50,
    position: 'absolute',
    color: theme.palette.grey[500],
  },
});

const iconStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ {
      src: LocationMarker,
      scale: 0.5,
    },
  ),
});

class MapComponent extends React.Component {
  state = {
    proj: 'EPSG:32633',
    baseProj: 'EPSG:4326',
    projection: null,
    imageExtent: null,
    map: null,
    imageLayer: null,
    areaLayer: null,
    featureSelect: null,
    updateAreaLayer: false,
  };

  componentDidMount() {
    proj4.defs(
      this.state.proj,
      '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs ',
    );
    register(proj4);
    const projection = getProjection(this.state.proj);

    const mapExtent = transformExtent(
      [2, 57, 30, 72],
      this.state.baseProj,
      this.state.proj,
    );

    const outlineLayer = new VectorLayer({
      title: 'Norway outline',
      type: 'base',
      source: new VectorSource({
        format: new KMLFormat(),
        url: '/map-areas/norway.kml',
      }),
    });

    const areaLayer = new VectorLayer({
      title: 'Norway outline',
      source: null,
      style: new Style({
        fill: new Fill({ color: 'transparent' }),
        stroke: new Stroke({ color: '#4A4A4A' }),
      }),
    });

    const imageExtent = transform(
      [2.2, 56.7],
      this.state.baseProj,
      this.state.proj,
    ).concat(transform([34.5, 71.6], this.state.baseProj, this.state.proj));

    const imageLayer = new ImageLayer({
      source: null,
    });

    const mapView = new View({
      extent: mapExtent,
      projection: projection,
      center: transform([12, 64], this.state.baseProj, this.state.proj),
      zoom: 3,
    });

    const map = new Map({
      target: 'mapContainer',
      layers: [outlineLayer, imageLayer, areaLayer],
      controls: [new Zoom()],
      view: mapView,
    });

    const featureSelect = new Select({
      style: new Style({
        stroke: new Stroke({ width: 3 }),
      }),
    });

    map.addInteraction(featureSelect);

    const innerThis = this;
    featureSelect.on('select', function (e) {
      var nSelected = e.target.getFeatures().getLength();
      if (nSelected > 0) {
        var i = e.target.getFeatures().item(0);
        var regionNumber = i.getProperties().REGION;
        if (typeof regionNumber != 'undefined') {
          innerThis.props.onAreaSelect(regionNumber);
        }
      }
    });

    mapView.fit(imageExtent, { size: map.getSize() });

    this.setState({ map: map });
    this.setState({ imageLayer: imageLayer });
    this.setState({ areaLayer: areaLayer });
    this.setState({ projection: projection });
    this.setState({ imageExtent: imageExtent });
    this.setState({ featureSelect: featureSelect });
  }

  shouldComponentUpdate(nextProps, nextState, context) {
    var propsChanged =
      nextProps.index !== this.props.index ||
      nextProps.period !== this.props.period ||
      nextProps.area !== this.props.area ||
      nextProps.year !== this.props.year ||
      nextProps.scenario !== this.props.scenario;
    return propsChanged || !nextState.imageLayer.getSource();
  }

  doSetLayerSource = (layer, url) => {
    if (!layer) {
      // no layer to set source
      return false;
    } else if (!layer.getSource()) {
      // set missing source
      return true;
    } else if (layer.getSource().getUrl()) {
      // set if wrong url
      return !layer.getSource().getUrl().endsWith(url);
    } else {
      return true;
    }
  };

  updateSelectedFeature = (featureSelect, areaLayer, regionId) => {
    if (!areaLayer || !areaLayer.getSource()) return false;
    var foundArea = false;
    if (regionId && regionId.length > 1) {
      if (featureSelect) featureSelect.getFeatures().clear();
      if (regionId.charAt(0) === 'R') {
        var regionNumber = regionId.substring(1, regionId.length);
        var fs = featureSelect;
        areaLayer.getSource().forEachFeature(function (area) {
          if (area.getProperties().REGION === regionNumber) {
            fs.getFeatures().push(area);
            foundArea = true;
          }
        });
      } else if (regionId.charAt(0) === 'C') {
        var fs = featureSelect;
        areaLayer.getSource().forEachFeature(function (area) {
          if (area.getProperties().REGION === regionId) {
            fs.getFeatures().push(area);
            foundArea = true;
          }
        });
      }
    }
    return foundArea;
  };

  render() {
    var doRenderMap = false;
    if (
      this.doSetLayerSource(this.state.areaLayer, this.props.index.regionName)
    ) {
      this.state.areaLayer.setSource(
        new VectorSource({
          format: new KMLFormat({
            extractStyles: false,
          }),
          url: this.props.index.regionName
            ? '/map-areas/' + this.props.index.regionName
            : null,
        }),
      );
      doRenderMap = true;
    }

    var sString = this.props.scenario.includes('85') ? 'rcp8.5' : 'rcp4.5';
    var imageSource = this.props.index.mapFilePattern
      .replace('%s', sString)
      .replace('%p', this.props.period.toLowerCase())
      .replace('%y', this.props.year);
    if (this.doSetLayerSource(this.state.imageLayer, imageSource)) {
      this.state.imageLayer.setSource(
        new ImageStatic({
          url: '/map-layers/' + imageSource,
          projection: this.state.projection,
          imageExtent: this.state.imageExtent,
        }),
      );
      doRenderMap = true;
    }

    if (
      this.updateSelectedFeature(
        this.state.featureSelect,
        this.state.areaLayer,
        this.props.area,
      )
    ) {
      doRenderMap = true;
    }

    if (doRenderMap) {
      this.state.map.render();
    }

    return (
      <div
        id="mapContainer"
        className={'map-container'}
        aria-label={this.props.intl.formatMessage({
          id: 'wcag_climate_projection_map_text',
        })}
        role="img"
      ></div>
    );
  }
}

MapComponent.propTypes = {
  index: PropTypes.object,
  period: PropTypes.string,
  scenario: PropTypes.string,
  area: PropTypes.string,
  year: PropTypes.string,
  onAreaSelect: PropTypes.func.isRequired,
};

const ScenarioMap = compose(withStyles(styles))(injectIntl(MapComponent));

export default ScenarioMap;
