import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts';
import HighchartsExport from 'highcharts/modules/exporting';
import { ClimateProjection } from '@Types/ClimateProjectionData';
import { useIntl } from 'react-intl';
import SenarioGraphText from './ScenarioGraphText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    chartSection: {
      border: '1px solid lightgray',
      paddingTop: '5px',
      marginBottom: theme.spacing(3),
    },
  });
});

interface SenarioGraphProps {
  scenarioTS: ClimateProjection;
  scenarioText: Record<string, string>;
  graphTitle: string;
  unit: string;
}

HighchartsExport(Highcharts);
highchartsMore(Highcharts);
const SenarioGraph: React.FC<SenarioGraphProps> = ({
  scenarioTS,
  scenarioText,
  graphTitle,
  unit,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const options = {
    title: {
      text: graphTitle,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    xAxis: {
      max: 2100,
      min: scenarioTS.data.obsValues[0]
        ? parseInt(scenarioTS.data.obsValues[0][0]) - 15
        : parseInt(scenarioTS.data.modelValues[0][0]) - 15,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: unit,
      valueDecimals: 1,
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: 'Observasjonsbasert',
        data: scenarioTS.data.obsValues,
        color: 'black',
        zIndex: 1,
        marker: {
          enabled: false,
        },
      },
      {
        name: intl.formatMessage({ id: scenarioTS.metadata.scenario }),
        data: scenarioTS.data.modelValues,
        color: scenarioTS.metadata.scenario === 'RCP85' ? 'red' : 'blue',
        zIndex: 1,
      },
      {
        name: '10 - 90 %',
        data: scenarioTS.data.minMaxModelValues,
        type: 'arearange',
        color: scenarioTS.metadata.scenario === 'RCP85' ? '#ff8080' : '#b3b3ff',
        lineWidth: 0,
        linkedTo: ':previous',
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <>
      <div
        className={classes.chartSection}
        aria-label={intl.formatMessage({
          id: 'wcag_climate_projection_graph_text',
        })}
        role="img"
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          immutable={true}
        />
      </div>
      <SenarioGraphText
        scenarioText={scenarioText}
        scenarioMetadata={scenarioTS.metadata}
      />
    </>
  );
};

export default SenarioGraph;
