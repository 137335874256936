import React from 'react';
import { useQuery } from 'urql';
import {
  Card,
  Link as MuiLink,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';

import { Section } from './Section';
import { getLinks } from 'cms/queries';
import { CmsResult, Link, RawData, isLink } from 'cms/types';
import { getUrlFromLinkContent, getLinkProps } from 'services/helpers';

interface Props {
  path: string;
  link?: Link;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      paddingTop: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    card: {
      width: 320,
      margin: 10,
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      [theme.breakpoints.down('md')]: {
        marginBottom: 30,
      },
    },
    cardLink: {
      '&:hover, &:focus': {
        textDecoration: 'underline',
        '& $cardImage': {
          opacity: 0.5,
        },
      },
    },
    textbox: {
      height: 150,
      overflow: 'hidden',
      padding: 10,

      color: 'black',
    },
    media: {},
    cardImageContainer: {
      height: 200,
    },
    cardImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center top',
      transition: 'opacity 0.2s ease-out',
    },
  }),
);

export const CardSection: React.FC<Props> = ({ path, link, ...props }) => {
  const classes = useStyles();
  const [result] = useQuery<CmsResult<RawData>>(
    getLinks('width(300)')({
      path: path,
    }),
  );

  const getAltText = (contentItem: Link) =>
    contentItem.data?.image?.data?.caption;

  const rawData = result.data?.guillotine.get;
  return rawData ? (
    <Section title={rawData.displayName} link={link}>
      <Grid container className={classes.cardContainer}>
        {rawData.children.map((contentItem) => (
          <Grid item key={`reportcard-${contentItem.displayName}`}>
            {isLink(contentItem) && (
              <Card className={classes.card}>
                <MuiLink
                  {...getLinkProps(
                    getUrlFromLinkContent(contentItem.data?.urls),
                  )}
                  variant="body1"
                  className={classes.cardLink}
                >
                  <Box className={classes.textbox}>
                    <Typography variant="h6">
                      {contentItem.displayName}
                    </Typography>
                    {contentItem.data && (
                      <Typography
                        variant="body1"
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: addEllipsis(
                            contentItem.data.text?.processedHtml,
                          ),
                        }}
                      />
                    )}
                  </Box>
                  <div className={classes.cardImageContainer}>
                    <img
                      src={contentItem.data?.image?.imageUrl}
                      className={classes.cardImage}
                      alt={getAltText(contentItem)}
                    ></img>
                  </div>
                </MuiLink>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
    </Section>
  ) : null;
};

function addEllipsis(input: string): string {
  if (input.length <= 55) {
    return input;
  }
  return input.substring(0, 55) + '...';
}
