import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getClimateLegends } from 'services/fetchData';
import { LegendData } from '../../@Types/ClimateLegend.d';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        legendContainer: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        legendTable: {
            borderSpacing: 0,
            width: '100%',
            tableLayout: 'fixed',
            border: '0px none transparent',
        },
        noBorder: {
            border: '0px none transparent',
        },
        colorRow: {
            borderTop: '0px none transparent',
            borderBottom: '0px none transparent',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
        },
        colorCell: {
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
            borderLeft: '0px none transparent',
            borderRight: '0px none transparent',
            height: theme.spacing(2)
        },
        labelCell: {
            textAlign: 'center',
            border: '0px none transparent',
            marginTop: '0px',
            paddingTop: '0px',
        },
    });
});

interface Props {
    legendFile: string;
}

export const ScenarioMapLegend: React.FC<Props> = ({ legendFile }) => {
    const classes = useStyles();
    const [data, setData] = useState<LegendData>();

    useEffect(() => {
        if (legendFile) {
            getClimateLegends('map-legends/' + legendFile).then(function (legend) {
                if (legend) {
                    const legendData = Object.values(legend);
                    setData(legendData[0]);
                }
            });
        }
    }, [legendFile]);

    const findColumnCount = (nElements: number): number => {
        const maxC = 5;
        if (nElements <= maxC) {
            return nElements;
        }
        for (let i = maxC; i > 2; i--) {
            if (nElements % i === 0)
                return i;
        }
        return maxC;
    }

    const nColumns = findColumnCount(data?.colors.length ? data?.colors.length : 5);
    const numberOfRows = Math.ceil(
        data?.colors.length ? data?.colors.length : nColumns / nColumns,
    );
    let tableRows = new Array<JSX.Element>();
    let count = 0;
    for (let i = 0; i < numberOfRows; i++) {
        let cells = new Array<JSX.Element>();
        let labelCells = new Array<JSX.Element>();
        for (let j = 0; j < nColumns; j++) {
            if (data?.colors.length && count < data?.colors.length) {
                cells.push(
                    <td
                        key={'scenario_cell_' + i + '_' + j}
                        className={classes.colorCell}
                        style={{ backgroundColor: '#' + data?.colors[count] }}
                    ></td>,
                );
                labelCells.push(
                    <td
                        key={'scenario_cell_label_' + i + '_' + j}
                        className={classes.labelCell}
                    >
                        <div dangerouslySetInnerHTML={{ __html: data?.labels[count] }} />
                    </td>,
                );
            }
            count++;
        }
        tableRows.push(
            <tr key={'scenario_row_' + i} className={classes.colorRow}>
                {cells}
            </tr>,
        );
        tableRows.push(
            <tr key={'scenario_row_label_' + i} className={classes.noBorder}>
                {labelCells}
            </tr>,
        );
    }

    return (
        <div className={classes.legendContainer}>
            <table className={classes.legendTable}>
                <caption style={{ captionSide: 'bottom' }}>
                    {' '}
                    <div
                        dangerouslySetInnerHTML={{ __html: data?.title ? data?.title : '' }}
                    />
                </caption>
                <tbody>{tableRows}</tbody>
            </table>
        </div>
    );
};
