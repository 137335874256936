import React, { useState, useRef } from 'react';
import {
  Button,
  ClickAwayListener,
  Link as MuiLink,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { UrlData } from 'cms/types';
import { getLinkProps, getUrlFromLinkContent } from 'services/helpers';

interface Props {
  labelId: string;
  items: Array<
    { title: string } & ({ href: string } | { urlData: UrlData | undefined })
  >;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdownContainer: {
    position: 'relative',
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textTransform: 'none',
    fontSize: 18,
    letterSpacing: 0.5,
  },
  paperContainer: {
    zIndex: 1,
    position: 'relative',
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    width: '100%',
  },
  link: {
    color: theme.palette.text.primary,
    fontSize: 14,
    whiteSpace: 'normal',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const Dropdown: React.FC<Props> = ({ labelId, items, ...props }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const toggleOpen = () => setOpen(!open);
  const closeDropdown = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.dropdownContainer}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={toggleOpen}
        variant="outlined"
        className={classes.button}
      >
        <FormattedMessage id={labelId} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </Button>
      {open && (
        <div className={classes.paperContainer}>
          <ClickAwayListener onClickAway={closeDropdown}>
            <Paper elevation={3} square className={classes.paper}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {items.map((linkItem) => (
                  <MuiLink
                    {...('href' in linkItem
                      ? { href: linkItem.href }
                      : getLinkProps(getUrlFromLinkContent(linkItem.urlData)))}
                    key={`mapLink-${linkItem.title}`}
                    onClick={closeDropdown}
                  >
                    <MenuItem className={classes.link}>
                      <Typography>{linkItem.title}</Typography>
                    </MenuItem>
                  </MuiLink>
                ))}
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
};
