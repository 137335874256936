import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { UTMtoLatLong } from '../../utils/locationUtils';
import { LocationData } from '../../@Types/LocationData';

interface CoordinateFieldsProps {
  setSelectedLocations: Dispatch<SetStateAction<LocationData[]>>;
  selectedLocations: LocationData[];
  locationSelectLimit: number;
  setLatLong: Dispatch<SetStateAction<{ lat: number; long: number }>>;
}

const IVFUtmFields: React.FC<CoordinateFieldsProps> = ({
  setSelectedLocations,
  selectedLocations,
  locationSelectLimit,
  setLatLong,
}) => {
  const [utmValues, setUtmValues] = useState({
    zone: 32,
    north: 6644917,
    east: 601690,
  });
  const intl = useIntl();
  const handleLocationChange = () => {
    const latLong = UTMtoLatLong(
      utmValues.zone,
      utmValues.north,
      utmValues.east,
    );

    const id = latLong[0].toString() + ' ' + latLong[1].toString();
    if (!selectedLocations.some((loc) => loc.id === id)) {
      const location: LocationData = {
        name:
          'z: ' +
          utmValues.zone +
          ' n: ' +
          utmValues.north +
          ' e:' +
          utmValues.east,
        id: id,
        isStation: false,
        utm: { z: utmValues.zone, n: utmValues.north, e: utmValues.east },
      };
      setSelectedLocations([location]);
    }
    setLatLong({ lat: latLong[1], long: latLong[0] });
  };

  return (
    <Grid container spacing={3} alignItems={'center'}>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label={intl.formatMessage({ id: 'utm_zone_label' })}
          onChange={(e) =>
            setUtmValues({ ...utmValues, zone: parseInt(e.target.value) })
          }
          type="number"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label={intl.formatMessage({ id: 'utm_north_label' })}
          onChange={(e) =>
            setUtmValues({ ...utmValues, north: parseFloat(e.target.value) })
          }
          type="number"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label={intl.formatMessage({ id: 'utm_east_label' })}
          onChange={(e) =>
            setUtmValues({ ...utmValues, east: parseFloat(e.target.value) })
          }
          type="number"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color={'primary'}
          size={'medium'}
          onClick={handleLocationChange}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default IVFUtmFields;
