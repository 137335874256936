import React from 'react';
import {
  Box,
  Grid,
  Theme,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import { ArticleSectionData } from 'cms/types';
import { headerHeight } from 'components/Header';
import { makeSlug } from 'services/helpers';

const useStyles = makeStyles((theme: Theme) => {
  const anchorOffset = {
    paddingTop: headerHeight.xs,
    marginTop: -1 * headerHeight.xs,
    [theme.breakpoints.up('sm')]: {
      paddingTop: headerHeight.sm,
      marginTop: -1 * headerHeight.sm,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: headerHeight.md,
      marginTop: -1 * headerHeight.md,
    },
  };

  return createStyles({
    articleAnchor: {
      ...anchorOffset,
    },
    articleContent: {
      '& a[id]': {
        display: 'block',
        ...anchorOffset,
      },
      '& p:first-child': {
        marginTop: 0,
      },
    },
    articleImageContainer: {
      maxWidth: 400,
    },
    articleImage: {
      width: '100%',
    },
    imageDescription: {
      '& p': {
        display: 'inline',
      },
    },
    factbox: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  });
});

interface Props extends ArticleSectionData {
  sectionKey: string;
}

export const ArticleSection: React.FC<Props> = ({
  sectionKey,
  blockHeading,
  blockAnchor,
  article,
  images,
  factbox,
  ...props
}) => {
  const classes = useStyles();

  const anchorId = blockAnchor
    ? blockAnchor
    : blockHeading
    ? makeSlug(blockHeading)
    : undefined;

  return (
    <Box className={classes.articleAnchor} id={anchorId}>
      {blockHeading && (
        <Typography variant="h2">{blockHeading.trim()}</Typography>
      )}
      <Typography
        variant="body1"
        component="div"
        className={classes.articleContent}
        dangerouslySetInnerHTML={{ __html: article?.processedHtml }}
      />
      {images.length > 0 && (
        <Grid container spacing={4}>
          {images.map(
            (image, imageIndex) =>
              image.image && (
                <Grid
                  item
                  xs={12}
                  sm={images.length > 1 ? 6 : 8}
                  key={`${sectionKey}-articleImage-${imageIndex}`}
                >
                  <Box className={classes.articleImageContainer}>
                    <img
                      src={image.image.imageUrl}
                      alt={image.image.data.caption}
                      className={classes.articleImage}
                    />
                    <Typography
                      variant="caption"
                      className={classes.imageDescription}
                      dangerouslySetInnerHTML={{
                        __html: image.text?.processedHtml,
                      }}
                    />
                    {image.image.data.artist.length > 0 && (
                      <Typography variant="caption">
                        Kilde: {image.image.data.artist.join(', ')}.
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ),
          )}
        </Grid>
      )}
      {factbox.length > 0 &&
        factbox.map((fb, fbIndex) => (
          <Typography
            key={`${sectionKey}-factbox-${fbIndex}`}
            variant="body1"
            component="div"
            className={`${classes.factbox} factbox`}
            dangerouslySetInnerHTML={{ __html: fb.content?.processedHtml }}
          />
        ))}
    </Box>
  );
};
