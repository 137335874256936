import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  createStyles,
  makeStyles,
  Theme,
  InputBase,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: 'auto',
      width: 'fit-content',
      border: '1px solid ' + theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
      },
    },
    greenBorder: {
      border: '1px solid ' + theme.palette.primary.main,
    },
    searchIcon: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: 0,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    input: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      transition: theme.transitions.create('width'),
      width: '20ch',
    },
  }),
);

interface Props {
  greenBorder?: boolean;
  isInHeader?: boolean;
}

export const SearchBar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const searchStringRef = useRef('');
  const intl = useIntl();

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null; //for some old browsers that do not support the syntax above
  };

  //TODO: Oppdater mer riktig url når sidene våre er indeksert av google
  const search = () => {
    const url =
      'https://www.google.com/search?as_sitesearch=klimaservicesenter.no&q=' +
      encodeURIComponent(searchStringRef.current);
    openInNewTab(url);
  };

  return (
    <form
      className={
        props?.greenBorder
          ? `${classes.search} ${classes.greenBorder}`
          : `${classes.search}`
      }
      onSubmit={() => search()}
    >
      <InputBase
        placeholder={`${intl.formatMessage({ id: 'search' })}…`}
        classes={{
          input: classes.input,
        }}
        inputProps={{
          'aria-label': 'search',
          tabIndex: 0,
        }}
        onChange={(e) => {
          searchStringRef.current = e.target.value;
        }}
      />
      <Button className={classes.searchIcon} type="submit">
        <SearchIcon />
      </Button>
    </form>
  );
};
