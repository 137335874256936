import React, { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Grid, TextField } from '@material-ui/core';
import { LocationData } from '../../@Types/LocationData';

interface CoordinateFieldsProps {
  setSelectedLocations: Dispatch<SetStateAction<LocationData[]>>;
  selectedLocations: LocationData[];
  locationSelectLimit: number;
  setLatLong: Dispatch<SetStateAction<{ lat: number; long: number }>>;
}

const IVFCoordinateFields: React.FC<CoordinateFieldsProps> = ({
  setSelectedLocations,
  selectedLocations,
  locationSelectLimit,
  setLatLong,
}) => {
  const [tmpLatLong, setTmpLatLong] = useState({ lat: 60, long: 10 });
  const handleLocationChange = () => {
    const id = tmpLatLong.long.toString() + ' ' + tmpLatLong.lat.toString();
    if (!selectedLocations.some((loc) => loc.id === id)) {
      const location: LocationData = {
        name:
          'lat: ' +
          tmpLatLong.lat.toFixed(2) +
          ' long: ' +
          tmpLatLong.long.toFixed(2),
        id: id,
        isStation: false,
      };
      setSelectedLocations([location]);
      setLatLong(tmpLatLong);
    }
  };

  const intl = useIntl();

  return (
    <Grid container spacing={3} alignItems={'center'}>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label={intl.formatMessage({ id: 'lat' })}
          type="number"
          onChange={(e) =>
            setTmpLatLong({ ...tmpLatLong, lat: parseFloat(e.target.value) })
          }
          variant="outlined"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label={intl.formatMessage({ id: 'long' })}
          type="number"
          onChange={(e) =>
            setTmpLatLong({ ...tmpLatLong, long: parseFloat(e.target.value) })
          }
          variant="outlined"
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color={'primary'}
          size={'medium'}
          onClick={handleLocationChange}
        >
          {intl.formatMessage({ id: 'search' })}
        </Button>
      </Grid>
    </Grid>
  );
};

export default IVFCoordinateFields;
