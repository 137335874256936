import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    kssLogo: {
      width: '100%',
    },
    kssHeaderLogo: {
      width: 250,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
    },
    logo: {
      height: 40,
      marginLeft: 8,
      marginRight: 8,
    },
  }),
);

type logoLinkProps = {
  url: string;
  id: string;
  alt: string;
  isInHeader?: boolean;
  width?: string;
};

type linkProps = {
  url: string;
  id: string;
};

export const ExternalLinkWithLogo: React.FC<logoLinkProps> = ({
  url,
  id,
  alt,
  width = '100px',
}) => {
  const styles = useStyles();
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <FormattedMessage id={id}>
        {(...fragments) => (
          <img
            className={styles.logo}
            src={process.env.PUBLIC_URL + fragments}
            alt={alt}
          />
        )}
      </FormattedMessage>
    </a>
  );
};

export const KssLogoLink: React.FC<logoLinkProps> = ({
  url,
  id,
  alt,
  isInHeader,
}) => {
  const styles = useStyles();
  return (
    <Link component={RouterLink} to={url} color="inherit">
      <FormattedMessage id={id}>
        {(...fragments) => (
          <img
            className={isInHeader ? styles.kssHeaderLogo : styles.kssLogo}
            src={process.env.PUBLIC_URL + fragments}
            alt={alt}
          />
        )}
      </FormattedMessage>
    </Link>
  );
};

export const InternalLink: React.FC<linkProps> = ({ url, id }) => {
  return (
    <Link component={RouterLink} to={url} color="inherit">
      <FormattedMessage id={id} />
    </Link>
  );
};
