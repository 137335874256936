import React from 'react';
import {
  Box,
  createStyles,
  Grid,
  Link as MuiLink,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { Link } from 'cms/types';
import { getUrlFromLinkContent, getLinkProps } from 'services/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      flexBasis: '48%',
      '&:nth-child(n+3)': {
        marginTop: theme.spacing(2),
      },
    },
    weatherbox: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      padding: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
        flexDirection: 'column',
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.8),
      },
    },
    icon: {
      display: 'flex',
      alignContent: 'center',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(1.5),
      },
      '& img': {
        height: 40,
        width: 40,
        [theme.breakpoints.up('md')]: {
          height: '2vw',
          width: '2vw',
          maxHeight: 40,
          maxWidth: 40,
        },
      },
    },
  }),
);

export const WeatherBox: React.FC<Link> = (props) => {
  const url = getUrlFromLinkContent(props.data?.urls);
  const icon = props.data?.image;
  const classes = useStyles();

  return (
    <Grid item className={classes.gridItem}>
      <MuiLink {...getLinkProps(url)}>
        <Grid container className={classes.weatherbox}>
          <Box className={classes.icon}>
            {icon && <img src={icon.mediaUrl} alt={icon.data?.caption} />}
          </Box>
          <Typography
            variant="body1"
            component="div"
            style={{ color: '#262957', textDecoration: 'underline' }}
          >
            {props.displayName}
          </Typography>
        </Grid>
      </MuiLink>
    </Grid>
  );
};
