import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, {
  SeriesArearangeOptions,
  SeriesLineOptions,
} from 'highcharts';
import { IVFGraphData, IVFGraphEntity } from '../../@Types/IVFGraphData';
import { IntlShape, useIntl } from 'react-intl';
import HighchartsExport from 'highcharts/modules/exporting';
import highchartsMore from 'highcharts/highcharts-more';
import { round } from 'lodash';
import { Box } from '@material-ui/core';
import { LocationData } from '@Types/LocationData';
import { createSubtitle, createTitle } from './IVFObservationPanel';
HighchartsExport(Highcharts); //adds the download menu to highcharts graphs
highchartsMore(Highcharts);

interface IVFGraphProps {
  ivfData: IVFGraphEntity;
  selectedLocation: LocationData;
}

const IVFGraph: React.FC<IVFGraphProps> = ({ ivfData, selectedLocation }) => {
  const intl = useIntl();
  const [options, setOptions] = useState<Highcharts.Options>();
  useEffect(() => {
    const series = convertGraphDataToSeries(ivfData.IVFGraphData, intl);

    const highestYvalue = getHighestYvalue(series);

    const unitFormatted = intl.formatMessage({
      id: ivfData.IVFGraphData[0].unit,
    });
    const options: Highcharts.Options = {
      chart: {
        height: 600,
      },
      title: {
        text: createTitle(selectedLocation, intl) ?? '',
      },
      subtitle: {
        text: createSubtitle(ivfData, intl),
      },
      plotOptions: {
        series: {
          animation: false,
          marker: { enabled: false },
        },
      },
      series,
      tooltip: {
        snap: 100,
        formatter: function () {
          return tooltipFormatter(this.point, unitFormatted);
        },
      },
      xAxis: {
        title: {
          text: intl.formatMessage({ id: 'durations_label' }),
        },
      },
      yAxis: {
        max: highestYvalue,
        title: {
          text: unitFormatted,
        },
      },
      credits: {
        enabled: false,
      },
    };
    setOptions(options);
  }, [ivfData, intl, selectedLocation]);

  return (
    <div
      aria-label={intl.formatMessage({
        id: 'wcag_ivf_graph_text',
      })}
      role="img"
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        immutable={true}
      />
      <Box display="flex" justifyContent="start" alignItems="center">
        {intl.formatMessage({ id: 'click_on_graph' })}
      </Box>
    </div>
  );
};

export default IVFGraph;

const convertGraphDataToSeries = (
  datas: IVFGraphData[],
  intl: IntlShape,
): (SeriesLineOptions | SeriesArearangeOptions)[] => {
  const colors = Highcharts.getOptions().colors!;
  return datas.map<SeriesLineOptions | SeriesArearangeOptions>(
    (data, index) => {
      const isAreaRange = data.type === 'arearange';

      let colorIndex = 0;
      if (index >= 2) {
        if (isAreaRange) {
          colorIndex = (index - 1) / 2;
        } else {
          colorIndex = index / 2;
        }
      }

      const dontShowMarkerOnAreaRange = isAreaRange
        ? { states: { hover: { enabled: false } } }
        : {};

      return {
        type: isAreaRange ? 'arearange' : 'line',
        data: data.data,
        name: isAreaRange
          ? undefined
          : data.frequency + ' ' + intl.formatMessage({ id: 'years' }),
        linkedTo: isAreaRange ? ':previous' : undefined,
        visible: isAreaRange ? false : true,
        events: {
          click: handleClickOnSeries,
        },
        color: colors[colorIndex],
        fillOpacity: isAreaRange ? 0.3 : undefined,
        marker: dontShowMarkerOnAreaRange,
      };
    },
  );
};

const handleClickOnSeries = (
  event: Highcharts.SeriesClickEventObject,
): void => {
  const seriesClickedOn = event.point.series;
  const allSeries = seriesClickedOn.chart.series;
  if (seriesClickedOn.type === 'arearange') {
    seriesClickedOn.hide();
  } else {
    const areaSeries = allSeries[seriesClickedOn.index + 1];
    if (areaSeries.visible) {
      areaSeries.hide();
    } else {
      allSeries.forEach((series) => {
        if (series.type === 'arearange' && series.visible) {
          series.hide();
        }
      });
      areaSeries.show();
    }
  }
};

function getHighestYvalue(
  series: (Highcharts.SeriesLineOptions | Highcharts.SeriesArearangeOptions)[],
) {
  try {
    const highestData = series[series.length - 1].data!;
    const highestIntensitiesMillimeter = highestData[
      highestData.length - 1
    ] as [number, number, number];

    const highestIntensitiesLsha = highestData[0] as [number, number, number];

    let highestYvalue = highestIntensitiesLsha[2];
    if (highestIntensitiesMillimeter[2] > highestIntensitiesLsha[2]) {
      highestYvalue = highestIntensitiesMillimeter[2];
    }

    return highestYvalue;
  } catch {
    return undefined;
  }
}

function tooltipFormatter(point: Highcharts.Point, unit: string): string {
  try {
    const pointSeries = point.series;
    const pointSeriesIndex = pointSeries.index;

    const lineSeriesIndex =
      pointSeries.type === 'arearange'
        ? pointSeriesIndex - 1
        : pointSeriesIndex;

    const allSeries = pointSeries.chart.series;

    const line = allSeries[lineSeriesIndex];
    const linePoint = line.points[point.index];

    const area = allSeries[lineSeriesIndex + 1];
    const areaPoint = area.points[point.index];

    const lineValueY = formatNumber(linePoint.y!);
    const areaLowValue = formatNumber(areaPoint.low!);
    const areaHighValue = formatNumber(areaPoint.high!);

    const header = `${linePoint.x} min, ${line.name} <br/>`;
    const body = `<strong>${lineValueY} ${unit} (${areaLowValue} - ${areaHighValue} ${unit})</strong>`;

    return `${header}${body}`;
  } catch {
    return '';
  }
}

const formatNumber = (num: number) => round(num, 1).toFixed(1);
