import React, {Dispatch, SetStateAction} from 'react';
import {Button, createStyles, Dialog, DialogTitle, Grid, makeStyles, TextField, Theme} from "@material-ui/core";
import copy from 'copy-to-clipboard';
import {FormattedMessage} from "react-intl";

interface ShareUrlDialogProps {
    onClose: Dispatch<SetStateAction<boolean>>,
    open: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: theme.spacing(2)
        },
    })
);

const ShareUrlDialog: React.FC<ShareUrlDialogProps> = ({ onClose, open}) => {
    const classes = useStyles();

    function handleClose() {
        onClose(false)
    }

    return(
        <Grid item xs={12} md={6}>
            <Dialog onClose={handleClose} open={open}  fullWidth={true}>
                <DialogTitle><FormattedMessage id="share"/></DialogTitle>
                <TextField
                    spellCheck={false}
                    className={classes.dialogContent}
                    value={window.location.href}
                    InputProps={{
                        endAdornment: (
                            <Button color="primary" style={{width: 150}} size={"medium"} onClick={() => copy(window.location.href)}><FormattedMessage id="copy_to_clipboard"/></Button>
                        ),
                    }}
                />
            </Dialog>
        </Grid>
    );
};

export default ShareUrlDialog;