import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import { getPageData } from 'cms/queries';
import { RawData, isArticle } from 'cms/types';
import { ArticlePage, ErrorPage, NotFoundPage } from './';

export const PageComponentSelector: React.FC = () => {
  const location = useLocation();
  const [result] = useQuery<{
    guillotine: { get?: RawData<unknown> };
  }>(
    getPageData({
      path: location.pathname,
    }),
  );

  React.useEffect(() => {
    const locale = new URLSearchParams(location.search).get('locale');
    document.documentElement.lang = locale ? locale : 'nb';
  }, [location.search]);

  if (result.fetching) {
    // TODO: Replace with Loader
    return <div>Henter data fra CMS</div>;
  }
  const rawData = result.data?.guillotine.get;
  if (rawData) {
    if (isArticle(rawData)) {
      return <ArticlePage {...rawData} />;
    }
  }

  if (result.error) {
    // TODO: Proper error handling
    return <ErrorPage errorMessage={result.error.message} />;
  }

  return <NotFoundPage />;
};
