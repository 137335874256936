import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  CssBaseline,
  Grid,
  Container,
  Box,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import createTheme from './services/createTheme';
import { NavigationProvider } from 'services/navigationContext';
import { LocalizationProvider } from 'services/localizationContext';
import { Header, headerHeight } from './components/Header';
import Footer from './components/Footer';
import { ScrollToTopButton } from './components/ScrollToTopButton';
import { NavigationScrollHandler } from './components/navigation';
import * as pages from './pages';
import { PageComponentSelector } from 'pages/PageComponentSelector';
import FooterLinks from 'components/FooterLinks';
import { IVFPage } from './pages';
import './print.css';
import { fetchConfig } from 'utils/configUtils';
import { APIContext } from './utils/contextUtils';
import {
  cacheExchange,
  Client,
  createClient,
  fetchExchange,
  Provider,
} from 'urql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: headerHeight.xs,
      [theme.breakpoints.up('sm')]: {
        paddingTop: headerHeight.sm,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: headerHeight.md,
      },
    },
    pageContent: {
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(8),
      },
    },
  }),
);

const App: React.FC = () => {
  const styles = useStyles();
  const [graphqlClient, setGraphqlClient] = React.useState<Client>();
  const [config, setConfig] = React.useState(new Map<string, string>());
  React.useEffect(() => {
    fetchConfig().then((data: Map<string, string>) => {
      setConfig(data);
      const client = createClient({
        url: data.get('REACT_APP_GRAPHQL_SERVICE_LOCATION')!,
        exchanges: [fetchExchange, cacheExchange],
      });
      setGraphqlClient(client);
    });
  }, []);
  if (!graphqlClient) return <></>;

  return (
    <MuiThemeProvider theme={createTheme()}>
      <APIContext.Provider value={config}>
        <Provider value={graphqlClient}>
          <BrowserRouter>
            <LocalizationProvider>
              <NavigationScrollHandler />
              <NavigationProvider>
                <Grid id="back-to-top-anchor">
                  <CssBaseline />
                  <Header />
                  <ScrollToTopButton />
                  <Box className={styles.content}>
                    <Container id="main-content" className={styles.pageContent}>
                      <Switch>
                        <Route exact path="/" component={pages.FrontPage} />
                        <Route
                          exact
                          path="/ivf"
                          render={(props) => <IVFPage {...props} />}
                        />
                        <Route
                          exact
                          path="/climateprojections"
                          component={pages.ClimateProjectionsPage}
                        />
                        <Route component={PageComponentSelector} />
                        <Route
                          exact
                          path="/404"
                          component={pages.NotFoundPage}
                        />
                      </Switch>
                    </Container>
                    <Box>
                      <FooterLinks />
                      <Footer />
                    </Box>
                  </Box>
                </Grid>
              </NavigationProvider>
            </LocalizationProvider>
          </BrowserRouter>
        </Provider>
      </APIContext.Provider>
    </MuiThemeProvider>
  );
};

export default App;
