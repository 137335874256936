import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Link, Box } from '@material-ui/core';
import { ExternalLinkWithLogo, KssLogoLink, InternalLink } from './Links';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#fff',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        minHeight: 56 + theme.spacing(2),
      },
      padding: theme.spacing(0),
    },
    footerContainer: {
      float: 'left',

      [theme.breakpoints.up('md')]: {
        maxWidth: 1280,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2),
      },
    },
    kssLogoBox: {
      float: 'left',
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        width: 400,
      },
    },
    logoGrid: {
      width: 400,
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(0),
        float: 'left',
        alignItems: 'center',
        minWidth: 620,
        maxWidth: 1280,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    linkList: {
      float: 'left',
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        float: 'right',
        width: 'auto',
      },
    },
    linkText: {
      fontSize: 14,
    },
  }),
);

const Footer: React.FC = () => {
  const styles = useStyles();

  return (
    <footer className={styles.root}>
      <Box
        className={styles.footerContainer}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box className={styles.kssLogoBox}>
          <KssLogoLink url="/" id="logoKss" alt="KSS logo" />
        </Box>
        <Box className={styles.logoGrid}>
          <ExternalLinkWithLogo
            url="https://www.met.no/"
            id="logoMet"
            alt="MET logo"
          />
          <ExternalLinkWithLogo
            url="https://www.nve.no/"
            id="logoNve"
            alt="NVE logo"
          />
          <ExternalLinkWithLogo
            url="https://www.norceresearch.no/"
            id="logoNorce"
            alt="NORCE research logo"
          />
          <ExternalLinkWithLogo
            url="https://www.kartverket.no/"
            id="logoKartverket"
            alt="Kartverket logo"
          />
          <ExternalLinkWithLogo
            url="https://www.bjerknes.uib.no/"
            id="logoBjerknes"
            alt="Bjerknes centre logo"
          />
        </Box>
        <Box className={styles.linkList}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography display="inline" noWrap className={styles.linkText}>
                {/* TODO: These internal URLs might be different in production. */}
                <InternalLink url="/kss/om-oss/personvern" id="privacy" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography display="inline" className={styles.linkText}>
                <InternalLink url="/kss/om-oss/kontakt" id="contact" />
              </Typography>
            </Grid>
            <Grid item>
              <Link
                rel="noopener"
                target="_blank"
                color="inherit"
                href="https://uustatus.no/nb/erklaringer/publisert/181bc5c8-8de3-4571-ab54-c49aa4aecdc8"
              >
                Tilgjengelighetserklæring
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
