import { StationData } from '../@Types/StationData';
import { IvfData } from '../@Types/IVFData';
import { ClimateProjection } from '../@Types/ClimateProjectionData.d';
import { ClimateIndex } from '@Types/ClimateIndex';
import { ClimateLegend } from '@Types/ClimateLegend';

const checkStatus = (res: Response) => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  } else {
    const error = new Error(res.statusText);
    throw error;
  }
};

export async function getIVFData(
  stationIdWithPrefix: string,
  unit: string,
  REACT_APP_RIM_API_V2_URL: string,
): Promise<IvfData | undefined> {
  const stationId = stationIdWithPrefix.slice(2);
  unit = unit === 'mm' ? 'mm' : 'lsha';
  const url =
    REACT_APP_RIM_API_V2_URL +
    `/ivf/values?type=IDF&settings=%7B%22StationID%22:${stationId},%22Unit%22:%22${unit}%22%7D`;
  try {
    const response = await fetch(url);
    const json = await response.json();
    return json.data;
  } catch (error) {
    console.log(error);
  }
}

export function getIVFStations(
  REACT_APP_RIM_API_V2_URL: string,
): Promise<StationData> {
  return fetch(REACT_APP_RIM_API_V2_URL + '/ivf/stations')
    .then(checkStatus)
    .then((res) => res.json())
    .catch((error) => console.log(error));
}

export function getScenaroTimeseries(
  index: string,
  period: string,
  area: string,
  scenario: string,
  REACT_APP_KSS_API_URL: string,
): Promise<ClimateProjection> {
  let url =
    REACT_APP_KSS_API_URL +
    '/climateProjections?climateIndex=' +
    index +
    '&period=' +
    period +
    '&area=' +
    area +
    '&scenario=' +
    scenario;
  return fetch(url)
    .then(checkStatus)
    .then((res) => res.json())
    .catch((error) => console.log(error));
}

export function getClimateIndices(
  REACT_APP_KSS_API_URL: string,
): Promise<Array<ClimateIndex>> {
  let url = REACT_APP_KSS_API_URL + '/climateIndices';
  return fetch(url)
    .then(checkStatus)
    .then((res) => res.json());
}

export function readScenarioText() {
  const fileUrl = '/scenario-text-timeseries.properties.txt';
  return fetch(fileUrl)
    .then((r) => r.text())
    .catch((error) => console.log(error));
}

export function readScenarioMapText() {
  const fileUrl = '/scenario-text-map.properties.txt';
  return fetch(fileUrl)
    .then((r) => r.text())
    .catch((error) => console.log(error));
}

export function propertiesToRecord(
  prop: Promise<string | void>,
): Record<string, string> {
  let rec: Record<string, string> = {};
  prop.then((text) => {
    if (text) {
      const data = text.substring(text.indexOf('\n') + 1); // remove the first line of text
      const lines = data.split('\n');
      for (let index in lines) {
        const keyText = lines[index].split('=');
        rec[keyText[0]] = keyText[1];
      }
    }
  });
  return rec;
}

export function getClimateLegends(filename: string): Promise<ClimateLegend> {
  return fetch(filename)
    .then(checkStatus)
    .then((res) => res.json());
}
