import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Link as MuiLink,
} from '@material-ui/core';
import { Grid, Box, Container } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useQuery } from 'urql';

import { CmsResult, RawData, isLink, Link } from 'cms/types';
import { getLinks } from 'cms/queries';
import { getUrlFromLinkContent } from 'services/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.primary.light,
      display: 'flex',
      padding: theme.spacing(2),
    },
    linkGrid: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        direction: 'column',
        justifyContent: 'center',
      },
    },
    linkContainer: {
      borderColor: theme.palette.primary.main,
      width: '90vw',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(35),
      },
    },
    linkContent: {
      width: '90vw',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(35),
      },
      padding: theme.spacing(2),
      fontSize: 14,
      color: theme.palette.primary.dark,
    },
  }),
);

const FooterLinks: React.FC = () => {
  const classes = useStyles();

  const [result] = useQuery<CmsResult<RawData>>(
    getLinks()({
      path: '/kss/forside/footer-eksterne-lenker/',
    }),
  );
  const rawData = result.data?.guillotine.get;
  const linkData = rawData?.children.filter((val) => isLink(val)) as Link[];

  return linkData ? (
    <Box className={classes.container} id="footerLinks">
      <Container disableGutters={true}>
        <Grid
          container
          className={classes.linkGrid}
          justifyContent="space-between"
          spacing={4}
          direction="row"
          wrap="wrap"
        >
          {linkData.map((link, id) => (
            <Grid item key={`footerLink-${id}`}>
              <Box
                className={classes.linkContainer}
                border={1.5}
                borderRadius={2}
                display="flex"
              >
                <MuiLink
                  href={getUrlFromLinkContent(link.data?.urls)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    className={classes.linkContent}
                  >
                    {link.displayName}
                    <OpenInNewIcon />
                  </Box>
                </MuiLink>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  ) : null;
};

export default FooterLinks;
