type StorageKey = 'KSSLocale';

export function loadStringFromLocalStorage<T>({
  key,
  returnIfNull,
}: {
  key: StorageKey;
  returnIfNull: T;
}): T {
  const data = window.localStorage.getItem(key);
  if (data === null) {
    return returnIfNull;
  }
  return (data as unknown) as T;
}
