import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Hidden,
  Link,
  List,
  ListItem,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'components/Dropdown';

interface Props {
  chapters: {
    anchorHref: string;
    title: string;
  }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      padding: theme.spacing(3),
      border: `2px solid ${theme.palette.divider}`,
    },
  },
  fullWidth: {
    width: '100%',
  },
  li: {
    padding: 0,
    '&:not(:first-child)': {
      marginTop: theme.spacing(1.5),
    },
  },
  paper: {
    background: theme.palette.background.default,
  },
  link: {
    color: theme.palette.text.primary,
    whiteSpace: 'normal',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    fontSize: '14px',
  },
}));

export const TableOfContents: React.FC<Props> = ({
  chapters,
  ...props
}: Props) => {
  const classes = useStyles();

  const dropdownItems = chapters.map(ch => ({
    title: ch.title.trim(),
    href: `#${ch.anchorHref}`
  }));

  return (
    <Box component="nav" className={classes.container} id="articleMenu">
      <Hidden smDown>
        <Typography variant="h3">
          <FormattedMessage id="content" />
        </Typography>
        <List>
          {chapters.map((chapter) => (
            <ListItem key={`toc-${chapter.title}`} className={classes.li}>
              <Link
                href={`#${chapter.anchorHref}`}
                variant="body1"
                className={classes.link}
              >
                {chapter.title.trim()}
              </Link>
            </ListItem>
          ))}
        </List>
      </Hidden>
      <Hidden mdUp>
        <Dropdown labelId="content" items={dropdownItems} />
      </Hidden>
    </Box>
  );
};
