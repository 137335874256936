import React from 'react';
import {
  Box,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useLocalizationContext } from 'services/localizationContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
      fontSize: 18,
      color: 'inherit',
    },
  }),
);

export const LanguageSelector: React.FC = () => {
  const { locale, updateLocale } = useLocalizationContext();

  const classes = useStyles();

  return (
    <Button
      onClick={() => updateLocale(locale === 'en' ? 'nb' : 'en')}
      className={classes.button}
    >
      <Box fontWeight={locale === 'nb' ? 'bold' : ''}>Norsk</Box>
      <Box>/</Box>
      <Box fontWeight={locale === 'en' ? 'bold' : ''}>English</Box>
    </Button>
  );
};
