import React from 'react';
import { useQuery } from 'urql';
import {
  Link as MuiLink,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
} from '@material-ui/core';

import { Section } from './Section';
import { getLink } from 'cms/queries';
import { CmsResult, Link, RawData, isLink } from 'cms/types';
import { getUrlFromLinkContent, getLinkProps } from 'services/helpers';

interface Props {
  path: string;
  link?: Link;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      paddingTop: 20,
      '&:hover': {
        '& $cardImage': {
          opacity: 0.5,
        },
      },
    },
    textbox: {
      color: 'black',
    },
    cardImage: {
      width: '100%',
      height: 85,
      transition: 'opacity 0.2s ease-out',
    },
  }),
);

export const WarmingStripes: React.FC<Props> = ({ path, ...props }) => {
  const classes = useStyles();
  const [result] = useQuery<CmsResult<RawData>>(
    getLink('wide(1030,85)')({
      path: path,
    }),
  );

  const getAltText = (contentItem: Link) =>
    contentItem.data?.image?.data?.caption;

  const rawData = result.data?.guillotine.get;
  const contentItem = rawData;

  return rawData && isLink(rawData) ? (
    <Section title={rawData.displayName}>
      {contentItem && isLink(contentItem) && (
        <Box className={classes.cardContainer}>
          <MuiLink
            {...getLinkProps(getUrlFromLinkContent(contentItem.data?.urls))}
            variant="body1"
          >
            <img
              src={contentItem.data?.image?.imageUrl}
              className={classes.cardImage}
              alt={getAltText(contentItem)}
            />
            {contentItem.data && (
              <Typography
                variant="body1"
                component="div"
                className={classes.textbox}
                dangerouslySetInnerHTML={{
                  __html: contentItem.data.text?.processedHtml,
                }}
              />
            )}
          </MuiLink>
        </Box>
      )}
    </Section>
  ) : null;
};
